import React from "react";
import { Container, Typography, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AgreementCheckBox } from "../../../component";

interface ProjectPreviewProps {
  children: React.ReactNode;
  /**
   * Toggle the state of the agreement checkbox
   */
  onAgreementChange: () => void;
  /**
   * State of the agreement checkbox
   */
  agreement: boolean;
}

/**
 * Component to return the project preview title and all content as a children
 */
export default function ProjectPreview({
  children,
  agreement,
  onAgreementChange,
}: ProjectPreviewProps) {
  const theme = useTheme();
  return (
    <Container sx={{ mt: 4, textAlign: "left" }}>
      <Paper
        square
        sx={{ paddingX: theme.spacing(10), paddingY: theme.spacing(5) }}
      >
        <Typography gutterBottom variant="formTitle" sx={{ my: 2 }}>
          Preview and publish
        </Typography>
        {children}
      </Paper>
      <AgreementCheckBox
        error={false}
        onChange={onAgreementChange}
        checked={agreement}
      />
    </Container>
  );
}
