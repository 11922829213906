/* eslint-disable jsx-a11y/media-has-caption */
import {
  Grid,
  Typography,
  IconButton,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Stack,
  Slider,
} from "@mui/material"
import React, { useEffect, useRef } from "react"
import MicIcon from "@mui/icons-material/Mic"
import StopIcon from "@mui/icons-material/Stop"
import { AudioRecorder, Metronome } from "../../util"
import { useNotification } from "../../context/NotificationProvider"
import { colors } from "../../theme/colors"
import { VolumeDown, VolumeUp } from "@mui/icons-material"

const containerStyle = {
  " & h5": {
    color: colors.teal,
  },
  " & h6": {
    color: colors.grey,
  },
  "button svg": {
    fontSize: "3rem",
  },
  "& audio": {
    height: "20px",
  },
  ".blink": {
    animation: "blinker 1s linear infinite;",
  },
  "@keyframes blinker": {
    "50%": {
      opacity: 0,
    },
  },
}

type RecordingSectionStateProps = {
  _id?: string
  metreUpper?: number
  tempo?: number
  instruments?: string[]
  handleUploadInstrumentMusic: (_instrument: string, _file: File | undefined) => void
}

/**
 * Project Detail Recording Section
 */
export default function RecordingSection(props: RecordingSectionStateProps) {
  const { metreUpper = 4, tempo = 0, instruments = [], handleUploadInstrumentMusic } = props
  const { openNotification } = useNotification()
  const [metrePlayer, setMetrePlayer] = React.useState<any>(null)
  const [isRecordingAllow, setIsRecordingAllow] = React.useState(false)
  const [isRecording, setIsRecording] = React.useState(false)
  const recorder = useRef<any>(null)
  const [audioVolume, setAudioVolume] = React.useState(0.1)
  const [countDown, setCountDown] = React.useState<number>(metreUpper * 2)
  const [countDownTimer, setCountDownTimer] = React.useState<any>(null)
  const [audioUrl, setAudioUrl] = React.useState<string | undefined>()
  const [audioBlob, setAudioBlob] = React.useState<Blob | undefined>()
  const [selectedInstrument, setSelectedInstrument] = React.useState<string>(instruments[0])

  useEffect(() => {
    const trigger = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        stream.getTracks().forEach((t) => t.stop())
        setIsRecordingAllow(true)
      } catch (error: any) {
        openNotification("ERROR", error.message)
      }
    }
    trigger()
    // TODO: need way to stop and clear when unmount
  }, [openNotification])

  useEffect(() => {
    const trigger = async () => {
      const ar = await AudioRecorder().init()
      recorder.current = ar
    }
    trigger()
    // TODO: need way to stop and clear when unmount
  }, [])

  useEffect(() => () => metrePlayer?.stop(), [metrePlayer])

  const handleStartRecording = async () => {
    if (!isRecordingAllow) return
    const metreSpeed = tempo / 100
    const msPerBeat = 600 / metreSpeed
    const metreAudio = new Metronome(msPerBeat, metreUpper, audioVolume)

    setCountDown(metreUpper * 2 + 1)
    metreAudio.play(metreUpper)
    setMetrePlayer(metreAudio)

    recorder.current?.startRecording()
    recorder.current?.on("stop", () => {
      setAudioBlob(recorder.current.self.audioBlobs)
      const audioUrl = URL.createObjectURL(recorder.current.self.audioBlobs)
      setAudioUrl(audioUrl)
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const interval = setInterval(() => {
      setCountDown((prevCount) => prevCount - 1)
    }, msPerBeat)

    setCountDownTimer(interval)
    setIsRecording(true)
  }

  const handleStopRecording = async () => {
    recorder.current?.stopRecording()
    metrePlayer.stop()
    setIsRecording(false)
    setCountDown(metreUpper * 2 + 1)
    clearInterval(countDownTimer)
  }

  const handleSubmitFile = async () => {
    if (!audioBlob) return

    const file = new File([audioBlob], `${selectedInstrument}_record`)

    handleUploadInstrumentMusic(selectedInstrument, file)
  }

  const handleReset = () => {
    location.reload()
  }

  const renderAudioPreview = () => {
    if (!audioUrl) return <></>

    return <audio controls src={audioUrl}></audio>
  }

  const handleSelectInstrument = (e: any) => {
    setSelectedInstrument(e?.target?.value)
  }

  const renderInstrumentSelect = () => {
    if (!audioUrl) return <></>

    const renderInstrumentGroup = () => {
      return instruments?.map((instrument) => (
        <FormControlLabel
          key={instrument}
          value={instrument}
          control={<Radio />}
          label={instrument}
        />
      ))
    }

    return (
      <Box sx={{ textAlign: "left" }}>
        <Typography variant="body1">Select Instrument</Typography>
        <RadioGroup
          aria-labelledby="project-upload-instrument-type-label"
          value={selectedInstrument}
          name="radio-buttons-group"
          onChange={handleSelectInstrument}
        >
          {renderInstrumentGroup()}
        </RadioGroup>
      </Box>
    )
  }

  const renderSubmitBtn = () => {
    if (!audioUrl) return <></>

    return (
      <Box textAlign={"right"}>
        <Button onClick={handleReset}>reset</Button>
        <Button onClick={handleSubmitFile} variant="contained">
          Submit
        </Button>
      </Box>
    )
  }

  const handleVolumnChange = (e: any) => {
    setAudioVolume(e.target?.value)
  }

  return (
    <Grid container sx={containerStyle}>
      <Grid item xs={12}>
        <Typography variant="h5">Recording</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box width={"50%"}>
          <Typography variant="body1">Metronome volume when recording</Typography>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <VolumeDown />
            <Slider
              aria-label="Volume"
              step={0.01}
              max={1}
              min={0}
              value={audioVolume}
              onChange={handleVolumnChange}
              disabled={isRecording}
            />
            <VolumeUp />
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} mt={2} sx={{ textAlign: "center" }}>
        <Box>
          {isRecording ? (
            <>
              <IconButton
                onClick={handleStopRecording}
                color="secondary"
                size="large"
                disabled={countDown > 0}
              >
                <StopIcon></StopIcon>
              </IconButton>
              {isRecording && countDown <= 0 ? (
                <>
                  <Typography variant="h5" className="blink">
                    Recording in progress
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h5">Recording Start In</Typography>
                  <Typography variant="h4">{countDown === 9 ? "" : countDown}</Typography>
                </>
              )}
            </>
          ) : (
            <>
              <IconButton onClick={handleStartRecording} color="primary" size="large">
                <MicIcon></MicIcon>
              </IconButton>
            </>
          )}
        </Box>
        <Box>
          {renderInstrumentSelect()}
          {renderAudioPreview()}
          {renderSubmitBtn()}
        </Box>
      </Grid>
    </Grid>
  )
}
