import * as React from "react"
import { NavLink, Link } from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import { MenuItem, Typography, Paper, Divider } from "@mui/material"
import Menu from "@mui/material/Menu"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined"
import { useUserInfo } from "../../context/UserInfoProvider"
import { createDownloadUrl, logOut } from "../../util"
import NotificationtList from "./NotificationList"

/**
 * AppBarMenu
 */
export default function AppBarMenu() {
  const [auth, setAuth] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorNotificationEl, setAnchorNotificationEl] = React.useState<null | HTMLElement>(null)

  const { userInfo, userNotification, updateUserNotification } = useUserInfo()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    logOut()
  }

  if (!userInfo) {
    return null
  }

  const { name, profileImage } = userInfo
  const profileImageUrl = createDownloadUrl(profileImage?.downloadUrl)

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-notification"
        aria-haspopup="true"
        onClick={(e) => {
          if (userNotification?.length > 0) setAnchorNotificationEl(e.currentTarget)
        }}
        color="inherit"
      >
        <NotificationAddOutlinedIcon />
      </IconButton>
      <Menu
        id="menu-notification"
        anchorEl={anchorNotificationEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorNotificationEl)}
        onClose={() => setAnchorNotificationEl(null)}
      >
        <Paper sx={{ width: "90vw", maxWidth: "500px" }}>
          <NotificationtList
            notification={userNotification}
            handleClickNotification={updateUserNotification}
          />
        </Paper>
      </Menu>

      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="inherit"
      >
        <Avatar alt="User Pic" src={profileImageUrl} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Paper sx={{ width: 200 }}>
          <MenuItem component={NavLink} to={`/user/self/portfolio`}>
            <ListItemAvatar>
              <Avatar alt="User Pic" src={profileImageUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary={
                <Typography variant="body2" color="primary">
                  View Profile
                </Typography>
              }
            />
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to={`/user/self/edit`}>
            Edit Profile
          </MenuItem>
          <MenuItem onClick={handleClose}>Terms of use</MenuItem>
          <Divider />
          <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
        </Paper>
      </Menu>
    </>
  )
}
