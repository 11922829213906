import React from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { MobileDatePicker } from "@mui/x-date-pickers";
import { TaskProps } from "../types"

type handleFormSubmitParams = {
  name: string
  description: string
  deadline: Date
  assignTo: string
}


type TaskFormProps = {
  handleFormSubmit: (_rawData: handleFormSubmitParams) => void
  handleDelete: () => void
  users: {
    name: string
    id: string
  }[]
  selectedTask?: TaskProps
}

/**
 * Project Details Task Form Component
 */
export default function TaskForm(props: TaskFormProps) {
  const { handleFormSubmit, handleDelete, users, selectedTask } = props
  const initStates = {
    name: "",
    description: "",
    deadline: new Date(),
    assignTo: ""
  }
  if (selectedTask) {
    Object.assign(initStates, {
      name: selectedTask.name,
      description: selectedTask.description,
      deadline: new Date(selectedTask.deadline),
      assignTo: selectedTask.assignTo._id
    })
  }
  const [taskStates, setStates] = React.useState(initStates)
  const updateState = (type: string, value: string | Date | null) => {
    setStates(prev => {
      return {
        ...prev,
        [type]: value
      }
    })
  }

  return (
    <FormControl sx={{ padding: 2}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{selectedTask ? `Edit Task` : `Create Task`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Task Subject"
            variant="outlined"
            fullWidth
            value={taskStates.name}
            onChange={e => updateState("name", e?.target?.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={taskStates.description}
            onChange={e => updateState("description", e?.target?.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <MobileDatePicker
              label="Deadline"
              value={taskStates.deadline}
              onChange={val => updateState("deadline", val)}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="task-select-user">Assign Task To</InputLabel>
            <Select
              labelId="task-select-user"
              id="task-select-user-c"
              value={taskStates.assignTo}
              label="Assign Task To"
              onChange={e => updateState("assignTo", e?.target.value)}
            >
              {users?.map(user => (<MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {
            selectedTask ? (
              <Button
              sx={{ float: "left", backgroundColor: "red" }}
              variant="contained"
              onClick={handleDelete}
            >Delete Task</Button>
            ) : <></>
          }
          <Button
            sx={{ float: "right" }}
            variant="contained"
            onClick={() => handleFormSubmit(taskStates)}
          >CONFIRM</Button>
        </Grid>
      </Grid>
    </FormControl>
  )
}