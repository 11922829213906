import React from "react";
import { PageTitle, Page, UserForm } from "../../component";
import { useUserInfo } from "../../context/UserInfoProvider";
import useFormValidation from "../../hooks/useFormValidation";
import { userFormValidation } from "../../component/UserForm/user";
import { UserFormStateProps } from "../../types";
import { UserAPI } from "../../api";

/**
 * This page will render the edit user page for authenticated user
 */
export default function EditUser() {
  const { userInfo } = useUserInfo();
  const { portfolio, ...rest } = userInfo || {};
  const {
    formState: userForm,
    updateFormState,
    hasErrorInForm,
    errors,
    isFormValid,
    formErrorDisplay,
    updateFormErrorDisplay,
  } = useFormValidation<UserFormStateProps>(userFormValidation, rest);

  React.useEffect(() => {
    updateFormState(userInfo);
  }, [updateFormState, userInfo]);

  const onSubmit = async () => {

    delete userForm.email
    delete userForm.name

    // Do not update password if they are the same
    if (userForm.password === userInfo.password) {
      delete userForm.password
    }

    await UserAPI.updateUser(userForm);

    if (userForm.profileImageFile) {
      await UserAPI.uploadProfileImage(userForm.profileImageFile)
    }

    window.location.assign('/user/self/portfolio'); // TODO: pretty ugly but works till we get notificaiotns going
  };
  return (
    <Page pageId={EditUser}>
      <PageTitle title="Edit User Profile" />
      <UserForm
        onSubmit={() => onSubmit()}
        setFormState={updateFormState}
        formState={userForm}
        hasErrorInForm={hasErrorInForm}
        isFormValid={isFormValid}
        errors={errors}
        formErrorDisplay={formErrorDisplay}
        updateFormErrorDisplay={updateFormErrorDisplay}
      />
    </Page>
  );
}
