/* eslint-disable react/no-multi-comp */
import React from "react"
import {
  SelectChangeEvent,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  Typography,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import clone from "lodash/clone"
import pull from "lodash/pull"
import without from "lodash/without"
import { PROJECT_TYPES } from "../../pages/StartProject/projectData"
import { ProjectFormStateProps } from "../../types"
import ProjectBasicInfo from "./ProjectBasicInfo"
import ProjectInstruments from "./ProjectInstruments"

interface ProjectFormProps {
  onSubmit: (_: ProjectFormStateProps) => void
  formState: ProjectFormStateProps
  setFormState: React.Dispatch<React.SetStateAction<ProjectFormStateProps>>
  isLoading?: boolean
  children?: React.ReactNode
  handleUploadImage: (_: File | null) => void
}

function ProjectTypeToggleButton(props: any) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("lg"))
  return (
    <>
      <Grid item xs={12} lg={4}>
        <ToggleButton {...props} fullWidth size="small">
          {props.value}
        </ToggleButton>
      </Grid>
      {matches && <Grid item sm={12} lg={1}></Grid>}
    </>
  )
}

export default function ProjectForm({
  onSubmit,
  formState,
  setFormState,
  handleUploadImage,
}: ProjectFormProps) {
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const [projectType, setProjectType] = React.useState(PROJECT_TYPES[0])
  const {
    name,
    description,
    tempo,
    metreLower,
    metreUpper,
    deadline,
    musicKey,
    styles,
    instruments: insts,
    imageUrl,
  } = formState

  const handlePreviewImage = (imageUrl: string) => {
    setFormState((prevState) => ({
      ...prevState,
      imageUrl,
    }))
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleMetreChange = (event: SelectChangeEvent<string>) => {
    const [metreUpper, metreLower] = event.target.value.split("/")

    setFormState((prevState) => ({
      ...prevState,
      metreUpper: Number(metreUpper),
      metreLower: Number(metreLower),
    }))
  }

  const handleCheckBoxToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    let newState: string[]
    setFormState((prevState) => {
      if (checked) {
        //@ts-ignore
        newState = [...prevState.styles, value.toLowerCase()]
      } else {
        //@ts-ignore
        newState = without(prevState.styles, value.toLowerCase())
      }
      return {
        ...prevState,
        styles: newState,
      }
    })
  }

  const handleTempoChange = (tempo: number) => {
    setFormState((prevState) => ({
      ...prevState,
      tempo,
    }))
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleDateChange = (date: string | null) => {
    if (date) {
      setFormState((prevState) => ({
        ...prevState,
        deadline: date,
      }))
    }
  }

  const handleAddInstrument = React.useCallback(
    (value: string) => {
      const instruments = clone(insts)
      const instrument = value.toLowerCase()
      instruments.push(instrument)
      setFormState((prevState) => ({
        ...prevState,
        instruments,
      }))
    },
    [insts, setFormState]
  )

  const handleRemoveInstrument = React.useCallback(
    (value: string) => {
      const instruments = clone(insts)
      const instrument = value.toLowerCase()
      pull(instruments, instrument)
      setFormState((prevState) => ({
        ...prevState,
        instruments,
      }))
    },
    [insts, setFormState]
  )

  return (
    <Container sx={{ mt: 4, textAlign: "left" }}>
      <Typography gutterBottom variant="formTitle">
        Project Type
      </Typography>
      <Grid container item xs={12} lg={6} sx={{ my: 2 }}>
        <ToggleButtonGroup
          color="primary"
          orientation={largeScreen ? "horizontal" : "vertical"}
          value={projectType}
          exclusive
          sx={{ flex: 1 }}
          onChange={(_, value) => setProjectType(value)}
        >
          {PROJECT_TYPES.map((label) => (
            <ProjectTypeToggleButton key={label} value={label} />
          ))}
        </ToggleButtonGroup>
      </Grid>

      <Typography gutterBottom variant="formTitle" sx={{ my: 2 }}>
        Basic Info
      </Typography>
      <ProjectBasicInfo
        handlePreviewImage={handlePreviewImage}
        handleUploadImage={handleUploadImage}
        handleSelectChange={handleSelectChange}
        handleTextChange={handleTextChange}
        handleDateChange={handleDateChange}
        handleTempoChange={handleTempoChange}
        handleMetreChange={handleMetreChange}
        name={name}
        imageUrl={imageUrl}
        description={description}
        deadline={deadline}
        tempo={tempo}
        metreUpper={metreUpper}
        metreLower={metreLower}
        musicKey={musicKey}
      />
      <ProjectInstruments
        selectedInstruments={insts}
        selectedStyles={styles}
        handleCheckBoxToggleChange={handleCheckBoxToggleChange}
        handleAddInstrument={handleAddInstrument}
        handleRemoveInstrument={handleRemoveInstrument}
      />
    </Container>
  )
}
