import { ToggleButton, Grid, useMediaQuery } from "@mui/material";
import { ToggleButtonProps } from "@mui/material/ToggleButton";
import { useTheme } from "@mui/material/styles";

/**
 *
 * A toggle button wrapped in MUI responsive to be used with mui Toggle Button Group
 */
export default function ResponsiveToggleButton(props: ToggleButtonProps) {
  const theme = useTheme();
  const matchesSize = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <>
      <Grid item xs={12} lg={4}>
        <ToggleButton {...props} size="small">
          {/* @ts-ignore */}
          {props.value}
        </ToggleButton>
      </Grid>
      {matchesSize && <Grid item sm={12} lg={1}></Grid>}
    </>
  );
}
