export const colors = {
  charcoal: "#2d2d2d",
  grey: "#bfbfbf",
  grey2: "#777777",
  orange: "#f56d00",
  orange30: "#f56d0078",
  teal: "#93F6C7",
  darkGrey2: "#575757",
  yellow: "#F5C744",
  white40: "#ffffff66"
};

export const uploadSectionContainerStyle = {
  "& .dzu-dropzone": {
    overflow: "hidden",
    "& span": {
      color: colors.orange
    },
    "& label": {
      color: colors.orange
    },
    "& .dzu-previewButton": {
      backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOSA2LjQxTDE3LjU5IDUgMTIgMTAuNTkgNi40MSA1IDUgNi40MSAxMC41OSAxMiA1IDE3LjU5IDYuNDEgMTkgMTIgMTMuNDEgMTcuNTkgMTkgMTkgMTcuNTkgMTMuNDEgMTJ6Ii8+PC9zdmc+") !important`
    },
    "& .dzu-submitButton": {
      backgroundColor: colors.orange
    }
  }
}