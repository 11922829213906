import React, { useEffect } from "react";
import Portfilio from "./Portfolio"
import { UserAPI } from "../../api";
import { useParams } from "react-router-dom";

/**
 * This is the user portfolio Page, this page should be viewable to anyone
 * The editing of portfolio page should be restricted to the user itself and possibly the admin
 */
export default function SelfPortfolioPage() {
  const { userId } = useParams();
  const [ userInfo, setUserInfo ] = React.useState<any>();
  
  useEffect(() => {
    const trigger = async () => {
      const { res, error} = await UserAPI.searchUserById(userId)
      setUserInfo(res)
    }

    trigger()
  }, [userId]);

  if (!userInfo) return (<></>)

  return (
    <>
      <Portfilio userData={userInfo}/>
    </>
  );
}
