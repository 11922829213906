import { ProjectProps } from "../../types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { createDownloadUrl } from "../../util";

export interface MusicItemProps {
  /**
   * A WMC project
   */
  project: ProjectProps;
}

/**
 * Music Player Component that will be attached to the music player
 */
export default function MusicItem({ project }: MusicItemProps) {
  const { image } = project
  const projectImageUrl = createDownloadUrl(image?.downloadUrl)

  return (
    <Card
      sx={{
        m: "5px",
        width: {
          xs: "calc(100% - 10px)",
          sm: "calc(100% - 10px)",
          md: "calc(50% - 10px)",
          lg: "calc(25% - 10px)",
        },
      }}
    >
      <Link to={`/projects/${project._id}`}>
        <CardMedia
          sx={{ height: 300 }}
          image={projectImageUrl}
          title="project cover img"
        />
      </Link>
      <CardContent>
        <Typography gutterBottom variant="h5">
          <Link to={`/projects/${project._id}`}>{project.name}</Link>
        </Typography>
      </CardContent>
    </Card>
  );
}
