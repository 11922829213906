import React from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import FormControlLabel from "@mui/material/FormControlLabel"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import IconButton from "@mui/material/IconButton"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Switch from "@mui/material/Switch"
import AddReactionIcon from "@mui/icons-material/AddReaction"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { Modal, MusicianRequestForm, RFragment } from "../../component"
import { useUserInfo } from "../../context/UserInfoProvider"
import { colors, uploadSectionContainerStyle } from "../../theme/colors"
import { Box, ClickAwayListener, Popover } from "@mui/material"
import Dropzone from "react-dropzone-uploader"
import { acceptMusicFileExt } from "../../types/uploadFileTypes"
import { updateUserAccessRight } from "../../util/auth-utils"
import { createDownloadUrl } from "../../util"
import WMC_LOGO from "../../assets/brands/logo-sq.png"

const infoContainerStyle = {
  " & h5": {
    color: colors.teal,
  },
  " & h6": {
    color: colors.grey,
  },
}

/**
 * Project Detail Info Section
 */
export default function Info(props: any) {
  const {
    _id: projectId,
    name,
    description,
    deadline,
    followers = [],
    instruments,
    imageUrl, //TODO: update usage for imageUrl
    image,
    like,
    metreUpper,
    metreLower,
    tempo,
    styles,
    status,
    updateHandler,
    musicKey,
    handleFinishProject,
    handleFollowProject,
    handleUnFollowProject,
    handleRequestToJoinProject,
    handleAcceptInviteToProject,
    handleDenyInviteToProject,
  } = props
  const { userInfo = {} } = useUserInfo()
  const { projects = [] } = userInfo || {}
  const { projectStatus } = updateUserAccessRight(projects, projectId)
  const userToProjectStatus = projectStatus[projectId]
  const [isMusicanRequestModalOpen, setIsMusicanRequestModalOpen] = React.useState(false)
  const [isFinishProjectModalOpen, setIsFinishProjectModalOpen] = React.useState(false)
  const [uploadFile, setUploadFile] = React.useState<undefined | File>(undefined)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [isUserFollowingProject, setIsUserFollowingProject] = React.useState(
    followers.includes(userInfo?._id)
  )

  const projectImageUrl = createDownloadUrl(image?.downloadUrl)

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreClose = () => {
    setAnchorEl(null)
  }

  const handleRequstToJoin = (data: { instrument: string }) => {
    handleRequestToJoinProject(data)
    setIsMusicanRequestModalOpen(false)
  }

  const handleFollowProjectToogle = (
    _event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (checked) {
      handleFollowProject()
      setIsUserFollowingProject(true)
    } else {
      handleUnFollowProject()
      setIsUserFollowingProject(false)
    }
  }

  const handleUpdateInfo = (key: string, value: any) => {
    const data = {
      [key]: value,
    }

    updateHandler(data)
  }

  const handleFinish = () => {
    handleFinishProject(uploadFile)
    setIsFinishProjectModalOpen(false)
  }

  const handleFileDrop = (
    fileDropParams: { meta: any; file: File },
    status: "done" | "removed"
  ) => {
    const { file } = fileDropParams
    if (status === "done") {
      setUploadFile(file)
    } else if (status === "removed") {
      setUploadFile(undefined)
    }
  }

  const handleInvite = (type: string) => {
    const { projects = [] } = userInfo
    const project = projects.find(
      (data: { project: { _id: any } }) => data.project._id === projectId
    )

    if (!project) return

    if (type === "accept") {
      handleAcceptInviteToProject({ projectId, instrument: project.instrument })
    } else {
      handleDenyInviteToProject({ projectId, instrument: project.instrument })
    }
  }

  const openMore = Boolean(anchorEl)
  const moreBtnId = openMore ? "project-detail-info-more" : undefined

  const followersCount = followers?.length
  let RequestToJoinBtn = <></>

  if (typeof userToProjectStatus === "undefined") {
    RequestToJoinBtn = (
      <Button
        variant="contained"
        startIcon={<AddReactionIcon />}
        onClick={() => setIsMusicanRequestModalOpen(true)}
      >
        Request to Join
      </Button>
    )
  } else if (userToProjectStatus === "REQUESTED") {
    RequestToJoinBtn = (
      <Button
        sx={{
          borderColor: `${colors.orange} !important`,
          color: `${colors.orange} !important`,
        }}
        disabled
        variant="outlined"
        startIcon={<CheckIcon />}
      >
        Requested to Join
      </Button>
    )
  }

  return (
    <Grid container marginTop={2} textAlign="left" sx={infoContainerStyle}>
      <Grid item container xs={12}>
        <Grid item xs={12} sm={3} padding={1}>
          <img src={projectImageUrl || imageUrl || WMC_LOGO} alt="project img" width="100%" />
        </Grid>
        <Grid item container xs={12} sm={9}>
          <Grid item xs={12}>
            <Typography variant="h3">{name}</Typography>
            <Typography variant="body1">{description}</Typography>
          </Grid>
          <Grid item xs={5} sm={3}>
            <Typography variant="body1">{followersCount} Followers</Typography>
          </Grid>
          <Grid item xs={7} sm={9} sx={{ textAlign: "right" }}>
            <RFragment allow={["GUEST"]}>
              <FormControlLabel
                control={<Switch checked={isUserFollowingProject} />}
                label="Follow the project"
                onChange={handleFollowProjectToogle}
              />
              {RequestToJoinBtn}
              <Modal
                open={isMusicanRequestModalOpen}
                handleToggleClose={() => setIsMusicanRequestModalOpen((prev) => !prev)}
              >
                <MusicianRequestForm
                  handleFormSubmit={handleRequstToJoin}
                  instruments={instruments}
                  user={userInfo}
                />
              </Modal>
            </RFragment>
            {status === "FINISHED" && (
              <Button disabled variant="outlined">
                FINISHED
              </Button>
            )}
            <RFragment allow={["ADMIN", "LEAD"]} show={status !== "FINISHED"}>
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button
                  variant={status === "PRIVATE" ? "contained" : "outlined"}
                  onClick={() => handleUpdateInfo("status", "PRIVATE")}
                >
                  Private
                </Button>
                <Button
                  variant={status === "PUBLIC" ? "contained" : "outlined"}
                  onClick={() => handleUpdateInfo("status", "PUBLIC")}
                >
                  Public
                </Button>
              </ButtonGroup>
              <IconButton
                aria-label="fingerprint"
                color="secondary"
                sx={{ padding: 0 }}
                aria-describedby={moreBtnId}
                onClick={handleMoreClick}
              >
                <MoreVertIcon />
                <Popover
                  id={moreBtnId}
                  open={openMore}
                  anchorEl={anchorEl}
                  onClose={handleMoreClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ClickAwayListener onClickAway={handleMoreClose}>
                    <Box sx={{ p: 1 }} onMouseLeave={handleMoreClose}>
                      <Button variant="contained" onClick={() => setIsFinishProjectModalOpen(true)}>
                        Finish Project
                      </Button>
                    </Box>
                  </ClickAwayListener>
                </Popover>
              </IconButton>
              <Modal
                open={isFinishProjectModalOpen}
                handleToggleClose={() => setIsFinishProjectModalOpen((prev) => !prev)}
              >
                <Grid item xs={12} sx={uploadSectionContainerStyle}>
                  <Dropzone
                    accept={acceptMusicFileExt}
                    multiple={false}
                    maxFiles={1}
                    inputContent="Upload Final Music"
                    onChangeStatus={handleFileDrop}
                    onSubmit={handleFinish}
                  />
                </Grid>
              </Modal>
            </RFragment>
            <RFragment allow={["INVITED"]}>
              <Grid item container xs={12} marginTop={2} spacing={3}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    startIcon={<CheckIcon />}
                    onClick={() => handleInvite("accept")}
                  >
                    Accept Invite
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={() => handleInvite("deny")}
                  >
                    Deny Invite
                  </Button>
                </Grid>
              </Grid>
            </RFragment>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} marginTop={2} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Information</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="subtitle2">Style</Typography>
          <Typography variant="body1">
            {styles.map((style: string) => style.capitalize()).join(", ")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Music Key</Typography>
          <Typography variant="body1">{musicKey}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Instruments</Typography>
          <Typography variant="body1">
            {instruments.map((instrument: string) => instrument.capitalize()).join(", ")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Tempo</Typography>
          <Typography variant="body1">{tempo}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Deadline</Typography>
          <Typography variant="body1">{new Date(deadline).toDateString()}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Metre</Typography>
          <Typography variant="body1">{`${metreUpper}/${metreLower}`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
