import React from "react"
import { Stack, Typography, Grid, Chip, Button, IconButton } from "@mui/material"
import { colors } from "../../../theme/colors"
import { Modal, MuiAvatar, MusicianRequestForm } from "../../../component"
import { Check } from "@mui/icons-material"
import CancelIcon from "@mui/icons-material/Cancel"
import { useParams } from "react-router-dom"
import { createDownloadUrl } from "../../../util"

const MusicianStyle = {
  padding: 1,
  "& .MuiTypography-subtitle1": {
    color: colors.grey,
    paddingBottom: 1,
  },
  "& .musician-avatar-stack .MuiGrid-container": {
    // Each avatar item
    paddingBottom: 1,
    "& div:nth-of-type(2)": {
      // Avatar Name
      wordBreak: "break-word",
      paddingLeft: 0,
      paddingRight: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      "& span": {
        color: colors.yellow,
      },
    },
    "& div:nth-of-type(3)": {
      // Avatar Action
      paddingLeft: 1,
      textAlign: "right",
      "& .MuiChip-outlined": {
        // Lead
        backgroundColor: colors.orange30,
        border: `solid 1px ${colors.orange}`,
      },
    },
  },
}

export interface Position {
  role: string
  status: string
  instrument: string
  user: User
}

export interface User {
  _id: string
  name: string
  status: string
  profileImageUrl: string
  profileImage: {
    downloadUrl: string
  }
  instruments: {
    type: string
  }[]
  styleOfInterest: string[]
}

export interface TabMusicianStateProps {
  instruments: string[]
  users: Array<Position>
  recommendedUsers: User[]
  handleApproveUserToProject: (
    _projectId: string | undefined,
    _userId: string,
    _instrument: string
  ) => void
  handleDenyUserToProject: (
    _projectId: string | undefined,
    _userId: string,
    _instrument: string
  ) => void
  handleInviteUserToProject: (_data: { userId: string; instrument: string }) => void
}

/**
 * Musician Component
 * @returns React.Component
 */
export default function Musician(props: TabMusicianStateProps) {
  const { projectId } = useParams()
  const {
    users,
    instruments,
    recommendedUsers,
    handleDenyUserToProject,
    handleApproveUserToProject,
    handleInviteUserToProject,
  } = props
  const [isMusicanInvitetModalOpen, setIsMusicanInviteModalOpen] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState(recommendedUsers[0])
  const musicianBtn = (status: string, instrument: string, role: string, handler: Function) => {
    if (status === "REQUESTED") {
      return (
        <>
          <Chip label={instrument} variant="filled" />
          <Button endIcon={<Check />} onClick={() => handler("approve")}>
            Accept
          </Button>
          <IconButton onClick={() => handler("deny")}>
            <CancelIcon />
          </IconButton>
        </>
      )
    }

    if (status === "INVITED") {
      return (
        <>
          <Chip label={instrument} variant="filled" />
          <IconButton onClick={() => handler("deny")}>
            <CancelIcon />
          </IconButton>
        </>
      )
    }

    return (
      <>
        <Chip label={instrument} variant="filled" />
        <Chip label={role} variant="outlined" />
      </>
    )
  }
  const Musicians = users.map((pos: Position, idx: number) => {
    const { user, role, status, instrument } = pos
    const { _id, name, profileImage } = user
    const profileImageUrl = createDownloadUrl(profileImage?.downloadUrl)
    const userUrl = `/user/${_id}/portfolio`
    const handleUserRequestToProject = (type: string) => {
      if (type === "approve") {
        handleApproveUserToProject(projectId, _id, instrument)
      } else {
        handleDenyUserToProject(projectId, _id, instrument)
      }
    }

    const renderBtn = musicianBtn(status, instrument, role, handleUserRequestToProject)

    return (
      <MuiAvatar
        key={idx}
        bodyTxt={name}
        subTxtBt={status.toLowerCase()}
        imgUrl={profileImageUrl}
        userUrl={userUrl}
        name={name}
      >
        {renderBtn}
      </MuiAvatar>
    )
  })

  const RecommendUsers = recommendedUsers.map((user: User, idx: number) => {
    const { _id, name, profileImage, status } = user
    const profileImageUrl = createDownloadUrl(profileImage?.downloadUrl)
    const userUrl = `/user/${_id}/portfolio`
    const inviteBtn = (
      <>
        <Button
          onClick={() => {
            setSelectedUser(recommendedUsers[idx])
            setIsMusicanInviteModalOpen(true)
          }}
        >
          Invite
        </Button>
      </>
    )

    return (
      <MuiAvatar
        key={idx}
        bodyTxt={name}
        subTxtBt={status?.toLowerCase()}
        imgUrl={profileImageUrl}
        userUrl={userUrl}
        name={name}
      >
        {inviteBtn}
      </MuiAvatar>
    )
  })

  return (
    <Grid container sx={MusicianStyle}>
      <Grid item xs={12}>
        <Typography variant="h5">Musician</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Current Team</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" className="musician-avatar-stack">
          {Musicians}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Recommended Musicians</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" className="musician-avatar-stack">
          {RecommendUsers}
        </Stack>
      </Grid>
      <Modal
        open={isMusicanInvitetModalOpen}
        handleToggleClose={() => setIsMusicanInviteModalOpen((prev) => !prev)}
      >
        <MusicianRequestForm
          handleFormSubmit={(rawData) =>
            handleInviteUserToProject({ userId: selectedUser._id, instrument: rawData.instrument })
          }
          instruments={instruments}
          user={selectedUser}
        />
      </Modal>
    </Grid>
  )
}
