import { getToken } from "../util/auth-utils";
import config from "../config/client-config"

const { apiUrl } = config;

type getRequestObj = {
  headers: {
    Authorization?: string
  }
}

type postRequestObj = {
  method: string
  body?: string | FormData
  headers: {
    "Content-Type"?: string
    Authorization?: string
  }
}

/**
 * Generic Get Request for sending request to API
 */
export async function getRequest({ url, params = {} }: { url: string; params?: object }) {
  let res, error
  try {
    const token = getToken()
    const opts: getRequestObj = {
      ...params,
      headers: {}
    }

    if (token) {
      opts.headers.Authorization = token
    }

    const rawResponse = await fetch(`${apiUrl}${url}`, opts);
    const responseJson = await rawResponse.json();

    res =  responseJson.content
  } catch (err) {
    error = err
  }

  return { res, error }
}

/**
 * Generic Post/Update request
 */
export async function postRequest({
  url,
  params = {},
  data,
  needToken = true,
}: {
  url: string;
  params?: object;
  data?: object;
  needToken?: boolean;
}) {
  let res, error, token;
  try {
    if (needToken) {
      token = getToken();
      if (!token) throw new Error("token is missing");
    }

    const opts: postRequestObj = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      ...params,
    };

    if (data && !(data instanceof FormData)) {
      opts.body = JSON.stringify(data);
    }

    if (data && data instanceof FormData) {
      delete opts.headers["Content-Type"];
      opts.body = data;
    }

    const rawResponse = await fetch(`${apiUrl}${url}`, opts);
    const responseJson = await rawResponse.json();

    if (rawResponse.status >= 300) {
      throw responseJson.error;
    }

    res = responseJson.content;
  } catch (err) {
    error = err;
  }

  return { res, error };
}