import { getRequest, postRequest } from "./request"

const User: any = {}

User.getSelf = async () => {
  return await getRequest({ url: `/user` })
}

User.login = async (provider: string, rawData: any) => {
  const params = {
    method: "POST",
  }
  const data = {
    ...rawData,
  }
  return await postRequest({
    url: `/user/login/${provider}`,
    params,
    data,
    needToken: false,
  })
}

User.createUser = async (rawData: any) => {
  const params = {
    method: "POST",
  }
  const data = {
    ...rawData,
  }
  return await postRequest({
    url: `/user/`,
    params,
    data,
    needToken: false,
  })
}

User.updateUser = async (data: any) => {
  const params = {
    method: "PUT",
  }
  return await postRequest({
    url: `/user/`,
    params,
    data,
  })
}

User.uploadProfileImage = async (file: File) => {
  const params = {
    method: "POST",
  }

  const data = new FormData()
  data.append("file", file)

  return await postRequest({
    url: `/user/profileImage`,
    params,
    data,
  })
}

User.searchUserById = async (userId: string) => {
  return getRequest({ url: `/user/search?type=id&id=${userId}` })
}

User.searchUserByInstruments = async (instruments: string[]) => {
  return getRequest({ url: `/user/search?type=instruments&instruments=${instruments.join(",")}` })
}

User.acceptInvite = async (rawData: any) => {
  const data = {
    ...rawData,
  }
  return postRequest({
    url: `/user/project/accept`,
    data,
  })
}

User.denyInvite = async (rawData: any) => {
  const data = {
    ...rawData,
  }
  return postRequest({
    url: `/user/project/deny`,
    data,
  })
}

User.getNotification = async (page = 0) => {
  return getRequest({ url: `/user/notification?page=${page}` })
}

User.updateNotification = async (id: string) => {
  const params = {
    method: "PUT",
  }
  const data = {
    status: "READ",
  }
  return postRequest({
    url: `/user/notification/${id}`,
    params,
    data,
  })
}

export default User
