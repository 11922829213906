import React from "react";
import {
  ListItem,
  ListItemText,
  Collapse,
  Chip,
  ListItemAvatar,
  Avatar,
  Paper,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

import { InstrumentCategoriesProps } from "./instrumentData";

interface InstrumentItemProps {
  handleAddInstrument: (
    _selected: string
  ) => void;
  handleRemoveInstrument: (
    _selected: string
  ) => void;
  instrument: InstrumentCategoriesProps;
  selectedInstruments: string[]
}

export const InstrumentItem = ({
  instrument,
  handleAddInstrument,
  handleRemoveInstrument,
  selectedInstruments
}: InstrumentItemProps) => {
  const [selectedPanel, setSelectedPanel] = React.useState<string | null>(null);
  const [selectedNumber, setSelectedNumber] = React.useState(0)
  const { label } = instrument;
  const options = instrument?.options
    ? instrument.options
    : [
        {
          label,
          value: label,
        },
      ];
  const open = label === selectedPanel;

  React.useEffect(() => {
    let count = 0
    options.forEach(opt => {
      if (selectedInstruments.includes(opt.value.toLowerCase())) {
        count++
      }
    })
    setSelectedNumber(count)
  }, [options, selectedInstruments])

  /* To control which, if any, of the panel is selected in parent state */
  const handleClick = () => {
    setSelectedPanel(open ? null : label);
  };

  const renderChip = (option: { label: string; value: string }) => {
    const value = option.value.toLowerCase()
    if (selectedInstruments.includes(value)) {
      return (
        <Chip
          label={option.label}
          color="primary"
          variant="outlined"
          onClick={() => handleRemoveInstrument(value)}
          onDelete={() => handleRemoveInstrument(value)}
        />
      );
    }
    return (
      <Chip
        key={value}
        label={option.label}
        color="primary"
        variant="outlined"
        onClick={() => handleAddInstrument(value)}
      />
    );
  };
  
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemAvatar>
          <Avatar
            variant="square"
            alt={label}
            sx={{
              width: 50,
              height: 50,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={label}
          secondary={
            <Typography color="primary">
              {selectedNumber ? `${selectedNumber} Instrument Selected` : ""}
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            flexDirection: "row",
            alignItems: "center",
          }}
          component="ul"
        >
          {options.map((option) => {
            return <ListItem
                    key={option.value}
                    >{renderChip(option)}</ListItem>;
          })}
        </Paper>
      </Collapse>
      <Divider variant="inset" component="li" />
    </>
  );
};
