import { useEffect } from "react";
import { Grid } from "@mui/material";
import { Page, PageTitle, ProjectItem } from "../../component";
import { ProjectProps } from "../../types";
import { useUserInfo } from "../../context/UserInfoProvider";

export type userProjectsProps = {
  instrument: string
  role: string
  project: ProjectProps
}

/**
 *
 * Page that lists all of the project
 * This is aka the Project/Job Centre Page
 */
export default function MyProjectsPage() {
  const { userInfo, updateUserInfo } = useUserInfo();

  useEffect(() => {
    updateUserInfo();
  }, []);

  if (!userInfo) return (<></>)

  const { projects } = userInfo;

  return (
    <Page pageId={MyProjectsPage} noPadding={true}>
      <Grid container padding={3}>
        <Grid item xs={12}>
          <PageTitle title="My Projects" />
        </Grid>
        <Grid item container xs={12} justifyContent={"flex-start"} columnSpacing={1}>
          {projects.map((projectInfo: userProjectsProps) => {
            const { project } = projectInfo
            return (
              <ProjectItem key={project._id} project={project as ProjectProps} />
            )
          })}
        </Grid>
      </Grid>
    </Page>
  );
}
