import { MenuItem } from "@mui/material"
import ListItemText from "@mui/material/ListItemText"
import CircleIcon from "@mui/icons-material/Circle"

interface InstrumentListProps {
  handleClickNotification?: (_id: string) => void
  notification?: any[]
}

/**
 * NotificationtList
 */
export default function NotificationtList({
  handleClickNotification = () => {},
  notification = [],
}: InstrumentListProps) {
  if (!notification) {
    return <></>
  }

  return (
    <>
      {notification.map((n: any) => {
        const { _id, status, message, projectId: project } = n
        const { name, _id: projectId } = project || {}

        return (
          <MenuItem
            key={_id}
            onClick={() => {
              handleClickNotification(_id)
              location.assign(`/projects/${projectId}`)
            }}
          >
            {status === "NEW" ? <CircleIcon sx={{ fontSize: 10, mr: 1, color: "red" }} /> : ""}
            <ListItemText primary={`${message} from ${name}`} />
          </MenuItem>
        )
      })}
    </>
  )
}
