import React from "react"
import { Box, Grid, Typography, Avatar, Stack, Tab, Tabs, Paper } from "@mui/material"
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline"
import { Page, PageTitle, TabPanel } from "../../component"
import { colors } from "../../theme/colors"
import { ProjectProps } from "../../types"
import MusicItem from "./MusicItem"
import groupBy from "lodash/groupBy"
import { createDownloadUrl } from "../../util"

/**
 * This is the user portfolio Page, this page should be viewable to anyone
 * The editing of portfolio page should be restricted to the user itself and possibly the admin
 */
export default function Portfolio(props: any) {
  const { userData, editBtn = <></>, isSelf = false } = props
  const [portfolioTab, setPortfolioTab] = React.useState(0)

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setPortfolioTab(newValue)
  }

  if (!userData) {
    return null
  }
  const { projects, styleOfInterest, instruments, profileImage } = userData
  const userProfileImageUrl = createDownloadUrl(profileImage?.downloadUrl)
  const tabsSelection = [<Tab key="public" label="Public" />, <Tab key="finish" label="Finish" />]
  const tabsKey = ["PUBLIC", "FINISHED"]
  if (isSelf) {
    tabsKey.unshift("PRIVATE")
    tabsSelection.unshift(<Tab key="private" label="Private" />)
  }
  /**
   * render different version of UI based on user media size
   */
  const renderDisplayPortfolios = () => {
    if (!projects.length) {
      return (
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          User do not have any projects
        </Typography>
      )
    }
    const userProjects = groupBy(projects, (project) => project.project.status)
    const projTab = (tab: string) => (userProjects[tab] ? Object.entries(userProjects[tab]) : [])

    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
          <Tabs
            variant="fullWidth"
            value={portfolioTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            {tabsSelection.map((ele) => ele)}
          </Tabs>
        </Box>
        {tabsKey.map((tab, index) => {
          return (
            <TabPanel key={tab} value={portfolioTab} index={index}>
              <Grid container justifyContent={"flex-start"} columnSpacing={2} padding={2}>
                {projTab(tab).map(([_, project]) => {
                  return (
                    <MusicItem
                      key={project.project._id}
                      project={project.project as ProjectProps}
                    />
                  )
                })}
              </Grid>
            </TabPanel>
          )
        })}
      </>
    )
  }

  return (
    <Page pageId={Portfolio} noPadding={true}>
      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ m: 3 }}>
        <PageTitle title="User Profile" />
        {editBtn}
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        <Stack />
        <Paper
          sx={{
            backgroundImage: `url(https://app-staging.womucon.com/static/media/logo.fa1fedaf5794f40995df.png)`,
            height: 300,
            width: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></Paper>
        <Avatar
          sx={{
            width: 150,
            height: 150,
            mt: -5,
          }}
          alt={userData?.name}
          src={userProfileImageUrl}
        />
        <Box sx={{ mt: 5, textAlign: "center" }}>
          <Typography variant="subtitle1" gutterBottom>
            {userData?.name || "WMC User"}
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <PeopleOutlineIcon />
            <Typography variant="subtitle1">{userData?.followers || "N"} Followers</Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack sx={{ mx: 2 }}>
        <Typography variant="h6" sx={{ color: colors.grey2 }}>
          Style
        </Typography>
        <Typography variant="body1">
          {styleOfInterest.map((style: string) => style.capitalize()).join(", ")}
        </Typography>
        <Box sx={{ height: "1rem" }}></Box>
        <Typography variant="h6" sx={{ color: colors.grey2 }}>
          Instruments
        </Typography>
        <Typography variant="body1">
          {instruments
            .map((style: { type: string; year: number }) => style.type.capitalize())
            .join(", ")}
        </Typography>
      </Stack>

      <Stack sx={{ mx: 1 }}>{renderDisplayPortfolios()}</Stack>
    </Page>
  )
}
