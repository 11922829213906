import { ProjectProps } from "../types"
import WMC_LOGO from "../assets/brands/logo-sq.png"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { MusicNote, PeopleAltOutlined } from "@mui/icons-material"
import { Chip, Box, Badge } from "@mui/material"
import { IconWithText } from "."
import useMUIMediaQuery from "../hooks/useMUIMedia"
import { Link } from "react-router-dom"
import { createDownloadUrl } from "../util"

export interface ProjectItemProps {
  /**
   * A WMC project
   */
  project: ProjectProps
}

/**
 * List of project
 */
export default function ProjectItem({ project }: ProjectItemProps) {
  const { isMobileUp } = useMUIMediaQuery()
  const { image, styles, instruments, status } = project
  const projectImageUrl = image?.downloadUrl ? createDownloadUrl(image?.downloadUrl) : WMC_LOGO

  return (
    <Card
      sx={{
        position: "relative",
        m: "5px",
        width: {
          xs: "100%",
          md: "calc(50% - 20px)",
          lg: "calc(33% - 10px)",
          xl: "calc(20% - 10px)",
        },
        display: {
          xs: "flex",
          md: "block",
        },
      }}
    >
      <Badge
        badgeContent={status}
        color={status === "PRIVATE" ? "info" : "primary"}
        sx={{ position: "absolute", top: 20, left: 40 }}
      ></Badge>
      <Link to={`/projects/${project._id}`}>
        <CardMedia
          sx={{
            height: {
              xs: "100%",
              md: 300,
            },
            width: {
              xs: window.innerWidth / 3,
              md: "100%",
            },
          }}
          image={projectImageUrl}
          title="project cover img"
        />
      </Link>
      <Box
        sx={{
          display: { xs: "flex", md: "block" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <Link to={`/projects/${project._id}`}>{project.name}</Link>
          </Typography>
        </CardContent>
        <CardActions>
          {[
            {
              iconComponent: <MusicNote />,
              text: `${project.users.length} ${isMobileUp ? "Musicians" : ""}`,
            },
            {
              iconComponent: <PeopleAltOutlined />,
              text: `${project.followers.length} ${isMobileUp ? "Followers" : ""}`,
            },
          ].map(({ iconComponent, text }) => {
            return <IconWithText key={text} iconComponent={iconComponent} text={text} />
          })}
        </CardActions>
        <CardActions sx={{ flexWrap: "wrap" }}>
          {instruments.map((ins) => (
            <Chip key={ins} label={ins} variant="filled" sx={{ mt: 1 }} />
          ))}
          {styles.map((style) => (
            <Chip key={style} label={style} variant="outlined" sx={{ mt: 1 }} />
          ))}
        </CardActions>
      </Box>
    </Card>
  )
}
