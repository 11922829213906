import React from "react";
import { Avatar, FormControlLabel, Checkbox, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface ImageCheckBoxProps {
  handleCheckBoxToggleChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  value: string;
  selectedItems: string[];
}

/*
  A clickable, listitem like checkbox
*/

export default function ImageCheckBox({
  handleCheckBoxToggleChange,
  selectedItems,
  value,
}: ImageCheckBoxProps) {
  return (
    <>
      <FormControlLabel
        //@ts-ignore
        onChange={(event) => handleCheckBoxToggleChange(event)}
        labelPlacement="start"
        control={
          <Checkbox
            data-testid={`img-${value}`}
            value={value}
            sx={{
              visibility: selectedItems.includes(value) ? "visible" : "hidden",
            }}
            checked={selectedItems.includes(value)}
            style={{ zIndex: 0 }}
            checkedIcon={<CheckIcon />}
          />
        }
        sx={{ flex: 1, ml: 1, mr: 0 }}
        label={
          <>
            <Avatar
              variant="square"
              alt={value}
              sx={{
                width: 35,
                height: 35,
              }}
            />
            <Typography sx={{ ml: 1 }}>{value}</Typography>
          </>
        }
      />
    </>
  );
}
