import { List } from "@mui/material";
import React from "react";
// @ts-ignore
import { instruments } from "@womucon/share";
import { InstrumentItem } from "./InstrumentItem";
import { InstrumentCategoriesProps } from "./instrumentData";

interface InstrumentListProps {
  handleAddInstrument?: (
    _selected: string
  ) => void;
  handleRemoveInstrument?: (
    _selected: string
  ) => void;
  selectedInstruments?: string[]
}

export function InstrumentList({
  handleAddInstrument=() => {},
  handleRemoveInstrument=() => {},
  selectedInstruments=[]
}: InstrumentListProps) {
  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {instruments
        .sort((a: InstrumentCategoriesProps, b: InstrumentCategoriesProps) => {
          if (a.label === "Other") return 1;
          if (b.label === "Other") return -1;
          return a.label.localeCompare(b.label);
        })
        .map((instrument: InstrumentCategoriesProps) => {
          return (
            <InstrumentItem
              selectedInstruments={selectedInstruments}
              key={instrument.label}
              instrument={instrument}
              handleAddInstrument={handleAddInstrument}
              handleRemoveInstrument={handleRemoveInstrument}
            />
          );
        })}
    </List>
  );
}

export default React.memo(InstrumentList, (prev, next) => {
  return (
    prev.handleAddInstrument === next.handleAddInstrument &&
    prev.handleRemoveInstrument === next.handleRemoveInstrument &&
    prev.selectedInstruments === next.selectedInstruments
  );
})