import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

interface ProjectStepperProps {
  components: React.ReactNode[];
  steps: Array<string>;
  /**
   * Callback function when cancel is pressed
   */
  onCancel: () => void;
  /**
   *
   * When Submit button is clicked
   */
  onSubmit: () => void;
}

export default function ProjectStepper({
  onSubmit,
  components,
  steps,
  onCancel,
}: ProjectStepperProps) {
  const [activeStep, setActiveStep] = React.useState(0);
  const isLastPage = activeStep === steps.length - 1;
  const isFirstPage = activeStep === 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    onCancel();
  };

  const handleSubmit = () => onSubmit();

  return (
    <Box sx={{ width: "100%" }}>
      <React.Fragment>
        {components[activeStep]}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          {isFirstPage ? (
            <Button color="inherit" onClick={handleReset} sx={{ mr: 1 }}>
              Cancel
            </Button>
          ) : (
            <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
          <Button
            onClick={isLastPage ? handleSubmit : handleNext}
            variant="contained"
          >
            {isLastPage ? "Publish" : "Next"}
          </Button>
        </Box>
      </React.Fragment>
    </Box>
  );
}
