import config from "../config/client-config";

const fileServer = config.fileServerUrl;

export const getCookieValue = (name: string) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const createDownloadUrl = (downloadUrl?: string, token?: string) => {
  let url = downloadUrl ? `${fileServer}/${downloadUrl}` : "";

  if (token && url) {
    url += `?token=${token}`;
  }

  return url;
};

export const loadScript = (src: string) => {
  // creates a <script> tag and append it to the page
  // this causes the script with given src to start loading and run when complete
  let script = document.createElement("script");
  script.src = src;
  document.head.append(script);
};

export { logOut } from "./auth-utils";
export { default as AudioRecorder } from "./recorder";
export { default as Metronome } from "./metronome";
