export * from "./userTypes";
export * from "./projectTypes";
export * from "./taskTypes"

declare global {
  interface String {
    /**
     * Capitalize string
     */
    capitalize() : string;
  }
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}