import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import CssBaseline from "@mui/material/CssBaseline"
import { theme } from "./theme/theme"
import { DrawerAppBar } from "./component"
import AppRouter from "./router/AppRouter"
import { NotificationProvider, UserInfoProvider } from "./context"

export default function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationProvider>
            <UserInfoProvider>
              <DrawerAppBar>
                <AppRouter />
              </DrawerAppBar>
            </UserInfoProvider>
          </NotificationProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}
