import { Grid, Avatar, Typography, Checkbox, IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";
import { TaskItemStateProps } from "../types"
import { colors } from "../theme/colors"


export default function TaskItem({
  name,
  description,
  deadline,
  userImg,
  done,
  userName,
  userUrl="",
  handleEdit= () => {},
  handleTaskCheckbox= (_val: boolean) => {}
}: TaskItemStateProps) {
  const doneStyle = done ? { textDecoration: "line-through" } : {}
  return (
    <Grid container>
      <Grid item xs={1}>
        <Checkbox onChange={(_e, val) => handleTaskCheckbox(val)}/>
      </Grid>
      <Grid item xs={9} sx={doneStyle}>
        <Typography variant="h6">
          <IconButton onClick={() => handleEdit()} sx={{color: colors.orange }}>
            <EditIcon />
          </IconButton>
          {name}
        </Typography>
        <Typography variant="body1">{description}</Typography>
        <Typography variant="caption">{deadline}</Typography>
      </Grid>
      <Grid item xs={2}>
        <IconButton>
          <Link to={userUrl}>
            <Avatar alt={userName} src={userImg} />
          </Link>
        </IconButton>
      </Grid>
    </Grid>
  )
}