import { Routes, Route, Navigate } from "react-router-dom"
import Auth from "../pages/Auth/Auth"
import EditUser from "../pages/User/EditUser"
import Projects from "../pages/Projects/ProjectsPage"
import ProjectDetailPage from "../pages/ProjectDetail/ProjectDetailPage"
import PrivateRoute from "./PrivateRoute"
import StartProject from "../pages/StartProject/StartProject"
import EditProjectDetailPage from "../pages/EditProjectDetail/EditProjectDetailPage"
import { ErrorFallback } from "../component"
import PortfolioPage from "../pages/Portfolio/PortfolioPage"
import SelfPortfolioPage from "../pages/Portfolio/SelfPortfolioPage"
import SignUpUserPage from "../pages/Signup/SignupPage"
import { ErrorBoundary } from "react-error-boundary"
import BrowseMusicPage from "../pages/BrowserMusic/BrowseMusicPage"
import ContactUs from "../pages/ContactUs/ContactUsPage"
import MyProjectsPage from "../pages/MyProjects/MyProjectsPage"
import { useUserInfo } from "../context"

/**
 * App Router
 */
export default function AppRouter() {
  const { userToken } = useUserInfo()
  return (
    <Routes>
      <Route path="/" element={userToken ? <Navigate replace to="/projects" /> : <Auth />} />
      <Route path="/auth" element={userToken ? <Navigate replace to="/projects" /> : <Auth />} />
      <Route
        path="/signup"
        element={userToken ? <Navigate replace to="/projects" /> : <SignUpUserPage />}
      />
      <Route path="/createUser" element={<PrivateRoute Component={Auth} />} />
      <Route path="/user/:userId/portfolio" element={<PrivateRoute Component={PortfolioPage} />} />
      <Route path="/user/self/portfolio" element={<PrivateRoute Component={SelfPortfolioPage} />} />
      <Route path="/user/self/projects" element={<PrivateRoute Component={MyProjectsPage} />} />
      <Route path="/user/self/edit" element={<PrivateRoute Component={EditUser} />} />
      <Route path="/projects" element={<PrivateRoute Component={Projects} />} />
      <Route path="/projects/:projectId" element={<PrivateRoute Component={ProjectDetailPage} />} />
      <Route
        path="/projects/:projectId/edit"
        element={<PrivateRoute Component={EditProjectDetailPage} />}
      />
      <Route path="/contactus" element={<PrivateRoute Component={ContactUs} />} />
      <Route
        path="/projects"
        element={
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Projects />
          </ErrorBoundary>
        }
      />
      <Route path="/startProject" element={<StartProject />} />
      <Route path="/music" element={<BrowseMusicPage />} />
      <Route path="*" element={<Navigate replace to="/projects" />} />
    </Routes>
  )
}
