import React from "react";
import { Grid, Paper, Typography, TextField, Button } from "@mui/material";
import { postRequest } from "../../api/request"
import { Page } from "../../component";

/**
 * Contact Us Form Component
 */
function ContactUs() {
  const [message, setMessage] = React.useState("");
  const [notificationText, setNotificationText] = React.useState("");
  const handleTextChange = (e: { target: { value: React.SetStateAction<string>; }; })=> setMessage(e.target.value);

  const handleFormSubmit = async () => {
    try { 
      postRequest({
        url: "/admin/contactus",
        data: {
          message
        }
      })
      setNotificationText("");
      setMessage("");
      setNotificationText("Thank you, we will review your question and get back to you as soon as possible");
    } catch (err) {
      console.log("err:", err);
      setNotificationText("Something went wrong, please try again");
    }
  }
  return (
    <Page pageId={ContactUs} noPadding={true}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6" style={{fontWeight: 700}}>
              Please type your question below
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField key="description" id="description" label="Description" margin="normal" name="description"
              multiline
              fullWidth={true}
              rows={4}
              value={message}
              onChange={handleTextChange}
              variant="outlined"
              helperText={notificationText ? notificationText : ""}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleFormSubmit}
              disabled={!message}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Page>
  )
}

export default ContactUs;