import * as React from "react"
import {
  SelectChangeEvent,
  Grid,
  TextField,
  Typography,
  Slider,
  Button,
  Stack,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { musicKeys, tempoMarks } from "../../pages/StartProject/projectData"
import { Dropdown, ImageCrop, Modal } from "../../component"
import { range } from "lodash"
import { Box } from "@mui/system"

interface ProjectBasicInfoProps {
  handleSelectChange: (_: SelectChangeEvent<string>) => void
  handleMetreChange: (_: SelectChangeEvent<string>) => void
  handleTextChange: (_: React.ChangeEvent<HTMLInputElement>) => void
  handleDateChange: (_: string | null) => void
  handleTempoChange: (_: number) => void
  handleUploadImage: (_: File | null) => void
  handlePreviewImage: (_: string) => void
  disabled?: boolean
  name: string
  description: string
  deadline: string
  tempo: number
  metreUpper: number
  metreLower: number
  musicKey: string
  imageUrl: string | undefined
}

export default function ProjectBasicInfo({
  handlePreviewImage,
  handleSelectChange,
  handleTextChange,
  handleDateChange,
  handleUploadImage,
  handleTempoChange,
  handleMetreChange,
  disabled = false,
  name,
  description,
  deadline,
  tempo,
  metreUpper,
  metreLower,
  musicKey,
  imageUrl = "",
}: ProjectBasicInfoProps) {
  const profileImgInputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [imgSrc, setImgSrc] = React.useState(imageUrl)

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setOpen(true)
      const reader = new FileReader()
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleSaveImage = (url: string, file: File) => {
    setImgSrc(url)
    handlePreviewImage(url)
    handleUploadImage(file)
    setOpen(false)
  }

  return (
    <Grid
      container
      spacing={4}
      sx={{
        "& .MuiTextField-root": { my: 1 },
        "& .MuiFormControl-root": { my: 1 },
      }}
    >
      <Modal open={open} handleToggleClose={() => setOpen(false)}>
        <Stack>
          <ImageCrop imgSrc={imgSrc} handleSaveImage={handleSaveImage} />
        </Stack>
      </Modal>
      <Grid item xs={12} lg={2}>
        <Box sx={{ position: "relative", mt: 1.5 }}>
          {imgSrc && <img src={imgSrc} alt="imagePreview" width="100%" />}
          <input
            ref={profileImgInputRef}
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={handleAddImage}
            accept={"image/*"}
          />
          <label
            htmlFor="contained-button-file"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
          >
            <Button
              component="span"
              variant="outlined"
              sx={{
                borderStyle: "dashed",
                textAlign: "center",
                height: "100%",
                p: 5,
                width: "100%",
              }}
            >
              {imgSrc ? "" : "Project Thumbnail"}
            </Button>
          </label>
        </Box>
      </Grid>
      <Grid item xs={12} lg={5}>
        <TextField
          required
          label="Name"
          fullWidth
          name="name"
          value={name}
          onChange={handleTextChange}
          disabled={disabled}
        />
        <TextField
          required
          label="Description"
          fullWidth
          name="description"
          value={description}
          onChange={handleTextChange}
          disabled={disabled}
          multiline
          rows={4}
        />
        <DatePicker
          label="deadline"
          value={deadline}
          onChange={handleDateChange}
          disabled={disabled}
          inputFormat="MM/dd/yyyy"
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <Dropdown
          value={musicKey}
          label="Music Key"
          onChange={handleSelectChange}
          options={musicKeys}
          name="musicKey"
          disabled={disabled}
        />
        <Grid container item xs={12} spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography>Tempo</Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Slider
              value={tempo}
              onChange={(e, value) => {
                handleTempoChange(value as number)
              }}
              min={60}
              max={160}
              marks={tempoMarks}
              valueLabelFormat={() => String(tempo)}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              required
              variant="standard"
              fullWidth
              name="tempo"
              value={tempo}
              onChange={handleTextChange}
              disabled={disabled}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography>Metre</Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Dropdown
              value={`${metreUpper}/${metreLower}`}
              label="Metre"
              onChange={handleMetreChange}
              options={[
                "1/4",
                "2/4",
                "3/4",
                "4/4",
                "3/8",
                "6/8",
                "9/8",
                "12/8",
                `${metreUpper}/${metreLower}`,
              ]}
              name="Metre"
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
