import { UserProjectProps } from "../context/UserInfoProvider"
import { useNavigate } from "react-router-dom"

export function logOut() {
  clearToken()
  location.assign("/auth")
}

/**
 * Save the token in local storage
 *
 */
export function saveToken(token: string) {
  localStorage.setItem("wmc_token", token)
}

/**
 * Clear the token from local storage
 *
 * @returns a wmc userToken
 */
export function clearToken() {
  localStorage.removeItem("wmc_token")
}

/**
 * Grabs the token from local storage
 *
 * @returns a wmc userToken
 */
export function getToken() {
  return localStorage.getItem("wmc_token")
}

/**
 * Determine a the user's role in a given project
 *
 * @param projects of user objects
 * @param projectId current project ID
 *
 * @returns a wmc userToken
 */
export function updateUserAccessRight(projects: UserProjectProps[], projectId: string) {
  let userAccessRole = "GUEST"
  let projectStatus = {} as any
  projects?.map((p) => {
    const { project } = p
    projectStatus[project._id] = p.status

    // Set current user Access Role
    if (project._id === projectId && p?.role && p.status === "ACTIVE") {
      if (p?.role && p.role !== userAccessRole) {
        userAccessRole = p.role
      }
    }

    // Set current user Access Role to INVITED
    if (project._id === projectId && p?.role && p.status === "INVITED") {
      if (p?.role && p.role !== userAccessRole) {
        userAccessRole = "INVITED"
      }
    }
  })
  return {
    projectStatus,
    userAccessRole,
  }
}
