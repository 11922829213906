import React from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  FormHelperText
} from "@mui/material";
import compact from "lodash/compact";
import startCase from "lodash/startCase";
import { recordingSoftwares } from "component/UserForm/user";

interface UserStyleProps {
  handleCheckBoxToggleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => void;
  recordingSoftware: string[];
  editFormErrorDisplay?: (_: string) => void;
  getErrorMessage?: (key: string) => string | undefined;
  shouldDisplayError?: (_: string) => boolean;
}

function UserRecordingSoftware({
  handleCheckBoxToggleChange,
  recordingSoftware,
  editFormErrorDisplay = () => {},
  getErrorMessage = () => undefined,
  shouldDisplayError = () => false,
}: UserStyleProps) {
  const handleChangingSoftware = (e: React.ChangeEvent<HTMLInputElement>) => {
    editFormErrorDisplay("recordingSoftware");
    handleCheckBoxToggleChange(e, "recordingSoftware");
  };

  const displayError = !!(
    shouldDisplayError("recordingSoftware") &&
    getErrorMessage("recordingSoftware")
  );

  return (
    <Grid container item xs={12}>
      <FormControl component="fieldset" error={displayError}>
        <FormLabel component="legend">
          {startCase("recordingSoftwares")}
        </FormLabel>
        {displayError && (
          <FormHelperText id="recordingSoftware-errors">
            {getErrorMessage("recordingSoftware")}
          </FormHelperText>
        )}
        <FormGroup
          sx={{
            flexDirection: "row",
            "& .MuiTypography-root": { flex: 1 },
            "& .MuiFormControlLabel-label": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          {compact(recordingSoftwares as string[]).map(
            (value: string, index: number) => (
              <Grid
                key={`${value}${index}`}
                container
                flexDirection="row"
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={value}
                      data-testid={`software-${value}`}
                      checked={recordingSoftware.includes(value)}
                      style={{ zIndex: 0 }}
                    />
                  }
                  label={startCase(value)}
                  //@ts-ignore
                  onChange={handleChangingSoftware}
                />
              </Grid>
            )
          )}
        </FormGroup>
      </FormControl>
    </Grid>
  );
}

export default React.memo(UserRecordingSoftware, (prevProps, nextProps) => {
  return (
    prevProps.recordingSoftware.length === nextProps.recordingSoftware.length &&
    nextProps.getErrorMessage?.("recordingSoftware") ===
      prevProps.getErrorMessage?.("recordingSoftware")
  );
});
