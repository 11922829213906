import React from "react";
import { useNavigate } from "react-router-dom";
import { PageTitle, Page } from "../../component";
import { ProjectFormStateProps } from "../../types";
import Info from "../ProjectDetail/Info";
import ProjectForm from "../../component/ProjectForm/ProjectForm";
import ProjectStepper from "./ProjectStepper";
import { ProjectFormInitiateState } from "./projectData";
import ProjectPreview from "./ProjectPreview/ProjectPreview";
import { ProjectAPI } from "../../api";
import { useNotification } from "../../context/NotificationProvider";

/**
 * The start project page that provides a form to create a project
 */
export default function StartProject() {
  const { openNotification } = useNotification()
  const [projectForm, setProjectForm] = React.useState<ProjectFormStateProps>(
    ProjectFormInitiateState
  );
  const [uploadImage, setUploadImage] = React.useState<File | null>(null);

  const [agreement, setAgreement] = React.useState(false);
  const navigate = useNavigate();
  const onAgreementChange = () => {
    setAgreement(!agreement);
  };

  const steps = ["1", "2"];
  const onCancel = () => {
    navigate("/projects");
  };

  const handleUploadImage = (file: File | null) => {
    setUploadImage(file);
  };

  const handleFormSubmit = async () => {
    const { imageUrl, ...rest } = projectForm; // Omitting imageUrl as thats only a base64 url for preview
    const { res, error } = await ProjectAPI.createProject(rest);

    if (error) {
      openNotification("ERROR", error.message)
    }

    if (uploadImage) {
      await ProjectAPI.uploadProjectImage(res._id, uploadImage);
    }
    location.assign(`/projects/${res._id}`);
  };

  return (
    <Page pageId={StartProject}>
      <PageTitle title={"Start Your Project"} />
      <ProjectStepper
        steps={steps}
        onSubmit={handleFormSubmit}
        onCancel={onCancel}
        components={[
          <ProjectForm
            key={1}
            onSubmit={() => {}}
            setFormState={setProjectForm}
            formState={projectForm}
            handleUploadImage={handleUploadImage}
          />,
          <ProjectPreview
            key={2}
            onAgreementChange={onAgreementChange}
            agreement={agreement}
          >
            <Info {...projectForm} />
          </ProjectPreview>,
        ]}
      />
    </Page>
  );
}
