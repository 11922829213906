import React from "react";
import { updateUserAccessRight } from "../../util/auth-utils";
import { useUserInfo } from "../../context/UserInfoProvider";
import { useParams } from "react-router-dom";
interface RFragmentPropsType {
  show?: boolean;
  allow?: string[];
  children?: React.ReactNode;
}

/**
 * This component show the children component base on condition
 * 1. value `show` will take higher priority to show the children component
 * 2. will check for current role against given allow roles
 * @param props
 * @returns
 */
export default function RFragment({
  show,
  allow,
  children,
}: RFragmentPropsType) {
  const { userInfo } = useUserInfo();
  const { projectId } = useParams();

  if (
    !projectId ||
    (typeof show === "undefined" && typeof allow === "undefined")
  ) {
    return <></>;
  }

  const { userAccessRole } = updateUserAccessRight(userInfo?.projects, projectId);
  const currentRole = userAccessRole;

  if (typeof show !== "undefined" && show === false) {
    return <></>;
  }

  if (Array.isArray(allow) && !allow.includes(currentRole)) {
    return <></>;
  }

  return <>{children}</>;
}
