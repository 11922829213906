import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  ListItemButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const NavItem = ({
  href,
  icon: Icon,
  title,
}: {
  href: string;
  icon: any;
  title: string;
}) => {
  return (
    <ListItem disableGutters>
      <ListItemButton component={RouterLink} to={href}>
        <ListItemIcon>
          <Icon size="20" />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
