import React from "react";
import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { recordingLevels } from "component/UserForm/user";
import { useTheme } from "@mui/material/styles";

interface RecordingLevelProps {
  handleChangeEducation: (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    value: number
  ) => void;
  recordingLevel: number;
}

function CustomToggleButton(props: any) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <>
      <Grid item xs={12}>
        <ToggleButton {...props} fullWidth />
        <Typography variant="body1" sx={{ color: "#BDBDBD", mb: 2 }}>
          {props.description}
        </Typography>
      </Grid>
      {matches && <Grid item sm={12} lg={1}></Grid>}
    </>
  );
}

function RecordingLevel({
  handleChangeEducation,
  recordingLevel,
}: RecordingLevelProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <ToggleButtonGroup
      color="primary"
      orientation={matches ? "horizontal" : "vertical"}
      value={recordingLevel}
      exclusive
      onChange={handleChangeEducation}
      data-testid="mp-recordingLevels"
    >
      {recordingLevels.map(({ description, label }, index) => (
        <CustomToggleButton
          key={label}
          value={index + 1}
          description={description}
        >
          {label}
        </CustomToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default React.memo(RecordingLevel, (prevProps, nextProps) => {
  return prevProps.recordingLevel === nextProps.recordingLevel;
});
