import React from "react"
import { getDisplayName } from "testUtils/queryUtils"
import { Stack } from "@mui/material"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "../ErrorFallback"

export type PageProps = {
  /** An optional prop for rendering arbitrary inline content above the Page header. */
  bannerOutlet?: React.ReactNode
  /** Any additional classes to render on the page. */
  className?: string
  /** The page content */
  children?: React.ReactNode
  /** Unique id for the page used for testing with the data-testid attribute */
  pageId: string | React.ComponentType<any>
  /** When true, no padding will be used to surround the page. */
  noPadding?: boolean
}

/**
 * Creates a basic inner page component with title and description.
 */
function Page({ bannerOutlet, children, className = "", pageId, noPadding = false }: PageProps) {
  const testId = typeof pageId === "string" ? pageId : getDisplayName(pageId)

  return (
    // @ts-ignore

    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack
        role="main"
        className={`${className}`}
        sx={{ overflow: "auto", margin: "auto" }}
        data-testid={testId}
      >
        {bannerOutlet}
        <Stack
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: noPadding ? 0 : 3,
          }}
        >
          {children}
        </Stack>
      </Stack>
    </ErrorBoundary>
  )
}

export default Page
