import React from "react";
import {
  Breakpoint,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

interface ModalProps {
  open: boolean;
  width?: Breakpoint;
  handleToggleClose: () => void;
  children: React.ReactNode;
  fullScreen?: boolean;
  closeIconAlignment?: "right" | "left";
}
/**
 *
 * This component wraps any given children inside MUI dialog with a close button
 */
const Modal = ({
  open,
  width = "md",
  handleToggleClose,
  fullScreen = false,
  children,
  closeIconAlignment = "right",
}: ModalProps) => {
  return (
    <Dialog open={open} maxWidth={width} fullWidth fullScreen={fullScreen}>
      <AppBar position="static">
        <Toolbar>
          {closeIconAlignment === "right" && (
            <Box sx={{ flex: 1 }} component="div"></Box>
          )}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleToggleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
};

export default Modal;
