import React, { useState } from "react";
import {
  IconButton,
  Typography,
  ToggleButtonGroup,
  Grid,
  TextField,
  InputAdornment,
  Box,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import {
  InstrumentList,
  Modal,
  MusicStyleList,
  ResponsiveToggleButton,
  TabPanel,
} from "../../component";
import SearchIcon from "@mui/icons-material/Search";
import { Container } from "@mui/system";
import Close from "@mui/icons-material/Close";
import without from "lodash/without";
import useMUIMediaQuery from "../../hooks/useMUIMedia";
import { clone, pull } from "lodash";

type SearchByTypes = "project" | "user";

interface ProjectSearchBarProps {
  handleSearchProjects: (_searchQuery?: string) => void;
}

/**
 *
 * This provides the search components for the parent to access our search function
 */
export default function ProjectSearchBar(props: ProjectSearchBarProps) {
  const { handleSearchProjects } = props;
  const [query, setQuery] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [searchBy, setSearchBy] = useState<SearchByTypes>("project");
  const [categoryTab, setCategoryTab] = React.useState(0);
  const [selectedStyles, setSelectedStyles] = React.useState<string[]>([]);
  const [selectedInstruments, setSelectedInstruments] = React.useState<
    string[]
  >([]);
  const { isTabletUp } = useMUIMediaQuery();

  /**
   *
   * The function takes a new instrument and check if a match could be found within the state,
   * if it does exist, then it will remove it from the state
   * if not, it will add to the array
   */
  const handleMusicStyleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    setSelectedStyles((prevState) => {
      if (checked) {
        return [...prevState, value.toLowerCase()];
      }
      return without(prevState, value.toLowerCase());
    });
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCategoryTab(newValue);
  };
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleSearchByChange = (
    _: React.MouseEvent<HTMLElement>,
    value: SearchByTypes
  ) => setSearchBy(value);
  const clearSearch = () => {
    setQuery("");
  };

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleInstrumentClick = (value: string, type: string) => {
    const instruments = clone(selectedInstruments);
    const instrument = value.toLowerCase();
    if (type === "ADD") instruments.push(instrument);
    else if (type === "REMOVE") pull(instruments, instrument);
    setSelectedInstruments(instruments);
  };

  const handleSearch = () => {
    let searchQuery = "";
    if (searchBy === "project") {
      if (selectedInstruments.length > 0) {
        searchQuery += `&instruments=${selectedInstruments.join(",")}`;
      }

      if (selectedStyles.length > 0) {
        searchQuery += `&styles=${selectedStyles.join(",")}`;
      }
    } else {
      searchQuery += `&user=${query}`;
    }
    searchQuery = searchQuery.slice(1);
    handleSearchProjects(searchQuery);
    handleClose();
  };

  /**
   *
   * render different version of UI based on user media size
   */
  const renderSearchByProject = () => {
    if (isTabletUp) {
      return (
        <>
          <Grid container item xs={6}>
            <Typography gutterBottom variant="formTitle" sx={{ my: 2 }}>
              Project Instrumentation
            </Typography>
            {renderInstrumentList()}
          </Grid>
          <Grid container item xs={6} alignSelf="flex-start">
            <Typography gutterBottom variant="formTitle" sx={{ my: 2 }}>
              Style
            </Typography>
            {renderMusicStyleList()}
          </Grid>
        </>
      );
    }
    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
          <Tabs
            variant="fullWidth"
            value={categoryTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Instruments" />
            <Tab label="Styles" />
          </Tabs>
        </Box>
        <TabPanel value={categoryTab} index={0}>
          {renderInstrumentList()}
        </TabPanel>
        <TabPanel value={categoryTab} index={1}>
          {renderMusicStyleList()}
        </TabPanel>
      </>
    );
  };

  const renderInstrumentList = () => (
    <InstrumentList
      selectedInstruments={selectedInstruments}
      handleAddInstrument={(val) => handleInstrumentClick(val, "ADD")}
      handleRemoveInstrument={(val) => handleInstrumentClick(val, "REMOVE")}
    />
  );

  const renderMusicStyleList = () => (
    <MusicStyleList
      displayTitle={false}
      styleOfInterest={selectedStyles}
      stateKey="instruments"
      handleCheckBoxToggleChange={handleMusicStyleChange}
      cols={{
        xs: 6,
      }}
    />
  );

  return (
    <>
      <IconButton aria-label="search" onClick={handleOpen}>
        <SearchIcon />
      </IconButton>
      <Modal
        fullScreen
        open={open}
        handleToggleClose={handleClose}
        closeIconAlignment="left"
      >
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} lg={3}>
              <Typography variant="h6" sx={{ my: 2 }}>
                Search By
              </Typography>
            </Grid>
            <Grid container item xs={12} lg={3}>
              <ToggleButtonGroup
                fullWidth
                color="primary"
                value={searchBy}
                exclusive
                onChange={handleSearchByChange}
              >
                {["project", "user"].map((value) => (
                  <ResponsiveToggleButton key={value} fullWidth value={value} />
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                margin="normal"
                fullWidth
                name="search"
                label="Search"
                variant="outlined"
                value={query}
                onChange={handleChangeText}
                InputProps={{
                  endAdornment: query && (
                    <InputAdornment position="end">
                      {" "}
                      <IconButton
                        aria-label="clear search input"
                        onClick={clearSearch}
                        onMouseDown={clearSearch}
                        edge="end"
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Button
                sx={{ margin: 2, padding: 1 }}
                variant="contained"
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </Grid>
            {searchBy === "project" && renderSearchByProject()}
          </Grid>
        </Container>
      </Modal>
    </>
  );
}
