import React from "react"
import { createDownloadUrl } from "../util"
import { UserAPI } from "../api"
import { getToken } from "../util/auth-utils"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { googleClientId } from "../config/client-config"

export type UserInfoProviderProps = {
  /**
   * The components to render within the provider
   */
  children: React.ReactNode
}

interface UserInfoContextProps {
  userInfo: any
  updateUserInfo: () => void
  userToken: string | null
  userNotification: any
  getUserNofitication: () => void
  updateUserNotification: (_id: string) => void
}

export type UserProjectProps = {
  status: string
  role?: string
  project: {
    _id: string
  }
}

const UserInfoContext = React.createContext<UserInfoContextProps | undefined>(undefined)

/**
 * Provide User Related Info
 */
export default function UserInfoProvider({ children }: UserInfoProviderProps) {
  const isAuthDisabled = process.env.REACT_APP_DISABLE_AUTH === "true"
  const [userInfo, setUserInfo] = React.useState(null)
  const userToken = isAuthDisabled ? "testToken" : getToken()
  const [userNotification, setUserNotification] = React.useState(null)
  const updateUserInfo = async () => {
    if (getToken() === null) return

    const { res } = await UserAPI.getSelf()
    const profileImageUrl = createDownloadUrl(res?.profileImage?.downloadUrl)
    res.profileImageUrl = profileImageUrl
    setUserInfo(res)
  }

  const getUserNofitication = async (page = 0) => {
    if (getToken() === null) return

    const { res } = await UserAPI.getNotification(page)
    setUserNotification(res)
  }

  const updateUserNotification = async (id: string) => {
    await UserAPI.updateNotification(id)
  }

  React.useEffect(() => {
    if (!isAuthDisabled) {
      updateUserInfo()
    }
  }, [isAuthDisabled])

  React.useEffect(() => {
    updateUserInfo()
    getUserNofitication()
  }, [])


  if (isAuthDisabled) {
    return (
      <UserInfoContext.Provider
        value={{
          userInfo,
          updateUserInfo,
          userToken,
          userNotification,
          getUserNofitication,
          updateUserNotification,
        }}
      >
        {children}
      </UserInfoContext.Provider>
    )
  }


  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <UserInfoContext.Provider
        value={{
          userInfo,
          updateUserInfo,
          userToken,
          userNotification,
          getUserNofitication,
          updateUserNotification,
        }}
      >
        {children}
      </UserInfoContext.Provider>

    </GoogleOAuthProvider>
    
  )
}

/**
 * User Info Context
 */
export function useUserInfo() {
  const context = React.useContext(UserInfoContext)
  if (context === undefined) {
    throw new Error("useUserInfo must be used within a UserInfoProvider")
  }
  return context
}
