import React from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import Dropdown from "./Dropdown";
import { instrumentsList } from "./UserForm/user";
import { InstrumentExperienceProps } from "../types/userTypes";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

interface InstrumentExpItemProps {
  instrument: InstrumentExperienceProps;
  disabled?: boolean;
  handleYearChange: (
    _e: any,
    _instrumentObj: InstrumentExperienceProps
  ) => void;
  handleSelectInstrument: (
    _selectedInstrument: SelectChangeEvent<string>,
    _instrumentObj: InstrumentExperienceProps
  ) => void;
  handleRemoveInstrument: (_id: string) => void;
  instrumentIndex: number;
  disableDelete: boolean;
}

const InstrumentExpItem = ({
  instrument,
  disableDelete,
  disabled = false,
  handleSelectInstrument,
  handleYearChange,
  handleRemoveInstrument,
  instrumentIndex,
}: InstrumentExpItemProps) => {
  const { type, year } = instrument;
  const [focus, setFocus] = React.useState<boolean>(false);

  const invalid =
    isNaN(parseInt(year, 10)) ||
    parseInt(year, 10) <= 0 ||
    parseInt(year, 10) > 100;
  const displayError = focus && invalid;
  return (
    <React.Fragment key={type}>
      <Grid item xs={1}>
        <Typography variant="caption">No. {instrumentIndex + 1}</Typography>
      </Grid>
      <Grid item xs={4} md={6}>
        <Dropdown
          value={type}
          error={displayError}
          helperText={"*"}
          label="Instrument"
          onChange={(e) => handleSelectInstrument(e, instrument)}
          options={instrumentsList}
          name="instrument"
          disabled={disabled}
          testId={`instrument-exp-type-${instrumentIndex}`}
        />
      </Grid>
      <Grid item xs={4} md={2}>
        <TextField
          id={type}
          type="number"
          error={displayError}
          label={"Year of Exp"}
          margin={"none"}
          name={"Year"}
          onBlur={() => setFocus(true)}
          value={year}
          required
          onChange={(e) => handleYearChange(e, instrument)}
          disabled={disabled}
          helperText={displayError ? "Please enter a valid year" : ""}
          inputProps={{
            "data-testid": `instrument-exp-year-${instrumentIndex}`,
          }}
        />
      </Grid>
      <Grid item xs={2} md={3}>
        <Button
          onClick={() => handleRemoveInstrument(type)}
          disabled={disableDelete || disabled}
          variant="text"
          color="secondary"
          startIcon={<RemoveCircleIcon />}
        >
          Delete
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default InstrumentExpItem;
