export default class Metronome {
  audioContext: AudioContext
  beatsPerBar: number
  msPerBeat: number
  nextNoteTime: number
  intervalID: any
  volume: number
  barCount: number
  constructor(msPerBeat = 80, beatsPerBar = 4, volume = 0.2) {
    this.audioContext = new window.AudioContext()
    this.beatsPerBar = beatsPerBar
    this.msPerBeat = msPerBeat
    this.nextNoteTime = 0 // when the next note is due
    this.volume = volume
    this.barCount = 0
  }

  nextNote() {
    // Advance current note and time by a quarter note (crotchet if you're posh)
    this.nextNoteTime += this.msPerBeat / 1000
  }

  scheduleNote(time: number, heavy?: boolean, mute?: boolean) {
    // create an oscillator
    const osc = this.audioContext.createOscillator()
    const envelope = this.audioContext.createGain()

    if (heavy) {
      osc.frequency.value = 1000
      this.barCount++
    } else {
      osc.frequency.value = 800
    }

    if (this.barCount > 3) {
      envelope.gain.value = this.volume
    } else {
      envelope.gain.value = 1
    }

    if (mute) {
      envelope.gain.value = 0
    }

    osc.connect(envelope)
    envelope.connect(this.audioContext.destination)

    osc.start(this.audioContext.currentTime + time)
    osc.stop(this.audioContext.currentTime + time + 0.03)
  }

  play(metre: number) {
    this.scheduleNote(this.nextNoteTime, true, true)
    this.nextNote()
    for (let i = 0; i < 1000; i++) {
      this.scheduleNote(this.nextNoteTime, i % metre === 0)
      this.nextNote()
    }
  }

  stop() {
    this.audioContext.suspend()
  }
}
