import { Alert, AlertTitle } from "@mui/material";
import { FallbackProps } from "react-error-boundary";

/**
 * A fallback component that should replace the broken component, an error object
 * and a resetErrorBoundary function should be provided to reset the broken component to its initial state
 *
 * @param error - a standard WMC object
 * @returns
 */
export default function ErrorFallback({ error,  resetErrorBoundary}: FallbackProps) {
  return (
    <Alert onClose={resetErrorBoundary} severity="error">
      <AlertTitle>Error</AlertTitle>
      Something went wrong - <strong>{error.message}</strong>
    </Alert>
  );
}
