import React from "react";
import { UserForm } from "../../component/UserForm";
import { PageTitle, Page } from "../../component";
import useFormValidation from "../../hooks/useFormValidation";
import {
  UserFormInitiateState,
  userFormValidation,
} from "../../component/UserForm/user";
import { UserFormStateProps } from "../../types";
import { useLocation } from "react-router-dom";
import { UserAPI } from "../../api";
import { useNotification } from "../../context/NotificationProvider";
import { saveToken } from "../../util/auth-utils";

/**
 * User Sign Up page
 */
export default function SignupUser() {
  const { search } = useLocation();
  const { openNotification } = useNotification();
  const email = new URLSearchParams(search).get("email") || "";

  const {
    formState: userForm,
    updateFormState,
    hasErrorInForm,
    errors,
    isFormValid,
    formErrorDisplay,
    updateFormErrorDisplay,
  } = useFormValidation<UserFormStateProps>(
    userFormValidation,
    UserFormInitiateState
  );

  const handleFormSubmit = async () => {
    const { res, error } = await UserAPI.createUser(userForm);

    if (error) {
      openNotification("ERROR", error.message);
      return;
    }
    saveToken(res.token);

    if (userForm.profileImageFile) {
      const { error: uploadProfileImageError } = await UserAPI.uploadProfileImage(userForm.profileImageFile);

      if (uploadProfileImageError) {
        openNotification("ERROR", uploadProfileImageError.message);
      return;
      }
    }

    location.assign("/projects");
  };

  React.useEffect(() => {
    if (email) {
      updateFormState({ email });
    }
  }, [email, updateFormState]);

  return (
    <Page pageId={SignupUser}>
      <PageTitle title="Signup User" />
      <UserForm
        onSubmit={handleFormSubmit}
        setFormState={updateFormState}
        formState={userForm}
        hasErrorInForm={hasErrorInForm}
        isFormValid={isFormValid}
        errors={errors}
        formErrorDisplay={formErrorDisplay}
        updateFormErrorDisplay={updateFormErrorDisplay}
      />
    </Page>
  );
}
