import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import React from "react"

import { colors } from "../theme/colors"
import { createDownloadUrl } from "../util"

type handleFormSubmitParams = {
  instrument: string
}

type MusicianRequestFormProps = {
  handleFormSubmit: (_rawData: handleFormSubmitParams) => void
  instruments: string[]
  user: {
    instruments: {
      type: string
    }[]
    styleOfInterest: string[]
    profileImageUrl: string
    profileImage: {
      downloadUrl: string
    }
    name: string
    _id: string
  }
}

/**
 * Musican Request to join project Form Component
 * @returns React.Component
 */
export default function MusicianRequestForm(props: MusicianRequestFormProps) {
  const initStates = {
    instrument: "",
  }
  const [formStates, setStates] = React.useState(initStates)
  const { handleFormSubmit, user, instruments } = props
  const { name, profileImage, _id: userId, instruments: userInstruments, styleOfInterest } = user
  const profileImageUrl = createDownloadUrl(profileImage?.downloadUrl)
  const updateState = (type: string, value: string) => {
    setStates((prev) => {
      return {
        ...prev,
        [type]: value,
      }
    })
  }

  return (
    <FormControl sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Select Instrument</Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
          <Link to={`/user/${userId}`}>
            <Avatar
              alt={name}
              src={profileImageUrl}
              sx={{ margin: "0 auto", width: "80%", height: "80%" }}
            />
          </Link>
          <Typography variant="h6">{name}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h6" sx={{ color: colors.grey2 }}>
            Style
          </Typography>
          <Typography variant="body1">
            {styleOfInterest?.map((i) => i.capitalize()).join(", ")}
          </Typography>
          <Box sx={{ height: "1rem" }}></Box>
          <Typography variant="h6" sx={{ color: colors.grey2 }}>
            Instruments
          </Typography>
          <Typography variant="body1">
            {userInstruments?.map((i) => i.type.capitalize()).join(", ")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="musician-select-user">Instrument</InputLabel>
            <Select
              labelId="musician-select-user"
              id="musician-select-user-c"
              value={formStates.instrument}
              label="Select Role"
              onChange={(e) => updateState("instrument", e?.target.value)}
            >
              {instruments?.map((instrument) => (
                <MenuItem key={instrument} value={instrument}>
                  {instrument}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ float: "right" }}
            variant="contained"
            onClick={() => handleFormSubmit(formStates)}
          >
            CONFIRM
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  )
}
