import * as React from "react"
import { SelectChangeEvent, Grid, TextField, Avatar, Button, Box } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { languages } from "./user"
import { Dropdown, Modal, ImageCrop } from ".."
import useMUIMediaQuery from "../../hooks/useMUIMedia"
import { startCase } from "lodash"

interface BasicInfoProps {
  handleSelectChange: (_: SelectChangeEvent<string>) => void
  handleTextChange: (_: React.ChangeEvent<HTMLInputElement>) => void
  handleDateChange: (_: string | null) => void
  handleUpdateProfileImage: (_file: File) => void
  editFormErrorDisplay: (_: string) => void
  getErrorMessage: (_key: string) => string | undefined
  shouldDisplayError: (_: string) => boolean
  disabled?: boolean
  name?: string
  language: string[]
  description: string
  dateOfBirth: Date | null | string
  email?: string
  password: string | undefined
  profileImageUrl: undefined | string
}

/**
 * Basic User Info component
 */
export default function BasicInfo({
  handleSelectChange,
  handleTextChange,
  handleDateChange,
  handleUpdateProfileImage,
  shouldDisplayError,
  editFormErrorDisplay,
  getErrorMessage,
  disabled = false,
  name,
  email,
  language,
  description,
  dateOfBirth,
  password,
  profileImageUrl = "",
}: BasicInfoProps) {
  const { isMobile } = useMUIMediaQuery()
  const profileImageRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [imgSrc, setImgSrc] = React.useState(profileImageUrl)

  const handleClearImage = () => {
    setImgSrc("")
    if (!profileImageRef.current) return

    profileImageRef.current.value = ""
  }

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setOpen(true)
      const reader = new FileReader()
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleSaveImage = (url: string, file: File) => {
    setImgSrc(url)
    setOpen(false)
    handleUpdateProfileImage(file)
  }

  const shouldDisplayDoBError = !!(
    shouldDisplayError("dateOfBirth") && getErrorMessage("dateOfBirth")
  )
  const shouldDisplayNameError = !!(shouldDisplayError("name") && getErrorMessage("name"))

  return (
    <Grid
      container
      columnGap={4}
      sx={{
        "& .MuiTextField-root": { my: 1 },
        "& .MuiFormControl-root": { my: 1 },
      }}
    >
      <Grid item sm={12} lg={1}>
        <Box
          width={{ sm: 300, lg: 100 }}
          sx={{ position: "relative", width: "100%", textAlign: "center" }}
        >
          <Modal open={open} handleToggleClose={() => setOpen(false)} fullScreen={isMobile}>
            <ImageCrop imgSrc={imgSrc} handleSaveImage={handleSaveImage} />
          </Modal>
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            ref={profileImageRef}
            onClick={handleClearImage}
            onChange={handleAddImage}
            accept={"image/*"}
          />
          <label
            htmlFor="contained-button-file"
            style={{
              position: "absolute",
              zIndex: 100,
              width: "100%",
              left: 0,
              height: "100%",
            }}
          >
            <Button
              component="span"
              sx={{ borderRadius: 50, textAlign: "center", width: "75%%" }}
            />
          </label>
          {imgSrc ? (
            <Avatar alt="Profile Picture" src={imgSrc} sx={{ width: "100%", height: "100%" }} />
          ) : (
            <Button variant="outlined">Upload Image</Button>
          )}
        </Box>
      </Grid>
      <Grid item sm={12} lg={5}>
        <TextField
          required
          label="Username"
          fullWidth
          name="name"
          value={name}
          onChange={handleTextChange}
          disabled={disabled}
          inputProps={{ "data-testid": "name" }}
        />
        <TextField
          label="Description"
          fullWidth
          name="description"
          value={description}
          onChange={handleTextChange}
          disabled={disabled}
          multiline
          rows={4}
          inputProps={{ "data-testid": "description" }}
        />
        <TextField
          required
          label="Email"
          fullWidth
          name="email"
          value={email}
          onChange={handleTextChange}
          inputProps={{ "data-testid": "email" }}
        />
      </Grid>
      <Grid item sm={12} lg={5}>
        <TextField
          onBlur={() => editFormErrorDisplay("password")}
          error={shouldDisplayNameError}
          helperText={shouldDisplayNameError ? getErrorMessage("password") : ""}
          required
          label="Password"
          fullWidth
          name="password"
          value={password}
          onChange={handleTextChange}
          disabled={disabled}
          type="password"
          inputProps={{ "data-testid": "password" }}
        />
        <DatePicker
          label="Date of birth"
          value={dateOfBirth}
          onChange={handleDateChange}
          disabled={disabled}
          views={["year", "month"]}
          inputFormat="MM/yyyy"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              onBlur={() => editFormErrorDisplay("dateOfBirth")}
              error={shouldDisplayDoBError}
              helperText={shouldDisplayDoBError ? getErrorMessage("dateOfBirth") : ""}
            />
          )}
        />
        <Dropdown
          testId="language"
          value={language[0]}
          label="What language can you communicate in?"
          onChange={handleSelectChange}
          options={languages}
          name="language"
          disabled={disabled}
          labelCallback={(label: string) => startCase(label)}
        />
      </Grid>
      <Grid item sm={12} lg={1}></Grid>
    </Grid>
  )
}
