import React from "react";
import { Grid } from "@mui/material";
import { useErrorHandler } from "react-error-boundary";
import { Page, PageTitle, ProjectItem } from "../../component";
import { ProjectProps } from "../../types";
import ProjectSearchBar from "./ProjectSearchBar";
import { ProjectAPI } from "../../api";

/**
 *
 * Page that lists all of the project
 * This is aka the Project/Job Centre Page
 */
export default function ProjectsPage() {
  const [projects, setProjects] = React.useState([]);
  const handleError = useErrorHandler();
  const handleGetAllPublicProjects = React.useCallback(
    async (searchQuery?: string) => {
      const { res, error } = await ProjectAPI.getAllPublicProject(searchQuery);
      handleError(error);
      if (res) {
        setProjects(res);
      }
    },
    [handleError]
  );

  React.useEffect(() => {
    handleGetAllPublicProjects();
  }, [handleGetAllPublicProjects]);

  return (
    <Page pageId={ProjectsPage} noPadding={true}>
      <Grid container padding={3}>
        <Grid item sm={10} md={4} lg={3} xl={2}>
          <PageTitle title="Project Center" />
        </Grid>
        <Grid item sm={2} md={8} lg={9} xl={10}>
          <ProjectSearchBar handleSearchProjects={handleGetAllPublicProjects} />
        </Grid>
        <Grid item container xs={12} justifyContent={"flex-start"} columnSpacing={1}>
          {projects.map((project: ProjectProps) => (
            <ProjectItem key={project._id} project={project as ProjectProps} />
          ))}
        </Grid>
      </Grid>
    </Page>
  );
}
