import React from "react"
import { Alert, AlertColor, Snackbar } from "@mui/material"

export type NotificationProviderProps = {
  /**
   * The components to render within the provider
   */
  children: React.ReactNode
}

type NotificationType = "ERROR" | "SUCCESS" | " INFO"

interface NotificationContextProps {
  openNotification: (_type: NotificationType, _msg: string) => void
}

type NotificationDefaultProp = {
  open: boolean
  msg: string
  severity: AlertColor
}

const NotificationContext = React.createContext<NotificationContextProps | undefined>(undefined)
const typeMap: {
  [key: string]: AlertColor
} = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
}

/**
 * Provide Notification action for sub page to call
 */
export default function NotificationProvider({ children }: NotificationProviderProps) {
  const defaultValue: NotificationDefaultProp = { open: false, msg: "", severity: "success" }
  const [notification, setNotification] = React.useState(defaultValue)
  const openNotification = (type: string, msg: string) => {
    const severity = typeMap[type]
    setNotification({ open: true, msg, severity })
  }

  const closeNotification = () => {
    setNotification({ open: false, msg: "", severity: "info" })
  }

  return (
    <NotificationContext.Provider
      value={{
        openNotification,
      }}
    >
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={closeNotification}
        message={notification.msg}
      >
        <Alert severity={notification.severity}>{notification.msg}</Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  )
}

/**
 * Notification Context
 */
export function useNotification() {
  const context = React.useContext(NotificationContext)
  if (context === undefined) {
    throw new Error("useNotification must be used within a NotificationProvider")
  }
  return context
}
