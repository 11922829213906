import React, { useEffect } from "react";
import Portfilio from "./Portfolio"
import { useUserInfo } from "../../context/UserInfoProvider";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
/**
 * This is the user portfolio Page, this page should be viewable to anyone
 * The editing of portfolio page should be restricted to the user itself and possibly the admin
 */
export default function SelfPortfolioPage() {
  const { userInfo, updateUserInfo } = useUserInfo();

  useEffect(() => {
    updateUserInfo();
  }, []);

  if (!userInfo) return (<></>)

  const editBtn = (
    <Button
      to={`/user/self/edit`}
      component={Link}
      variant="text"
      color="primary"
    >
      Edit
    </Button>
  )

  return (
    <>
      <Portfilio
        isSelf={true}
        userData={userInfo}
        editBtn={editBtn}
      />
    </>
  );
}
