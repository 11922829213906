import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

interface AgreementProps {
  checked: boolean;
  error: boolean;
  onChange: () => void;
}

export default function AgreementCheckBox({
  checked,
  onChange,
  error,
}: AgreementProps) {
  return (
    <FormGroup>
      <FormControlLabel
        sx={{ color: error ? "red" : "white" }}
        onChange={onChange}
        control={
          <Checkbox
            value={checked}
            checked={checked}
            data-testid="user-form-agreement"
          />
        }
        label="Agree with our Terms of Use, Privacy Policy, and Cookies Policy. You may receive notification from us periodically and you may opt out at anytime."
      />
    </FormGroup>
  );
}
