import React from "react";
import { Typography } from "@mui/material";

export default function PageTitle({ title }: { title: string }) {
  return (
    <Typography gutterBottom variant="h4">
      {title}
    </Typography>
  );
}
