import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material"

type DropdownProps = {
  onChange: (val: SelectChangeEvent<string>) => void
  value: string
  options: Array<string>
  name: string
  label: string
  disabled?: boolean
  testId?: string
  error?: boolean
  helperText?: string
  labelCallback?: (label: string) => string
}

export default function Dropdown({
  onChange,
  value,
  options,
  name,
  label,
  testId,
  disabled = false,
  error = false,
  helperText = "",
  labelCallback,
}: DropdownProps) {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id="dropdown-standard-label">{label}</InputLabel>
      <Select
        data-testid={testId}
        fullWidth
        variant="outlined"
        labelId="dropdown-standard-label"
        id="dropdown-standard"
        value={value}
        name={name}
        onChange={onChange}
        label={label}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {labelCallback ? labelCallback(option) : option}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText id={`dropdown-${testId}-error`}>{helperText}</FormHelperText>}
    </FormControl>
  )
}
